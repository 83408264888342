import React from "react"
import { theme } from "./theme"
import { Router } from "react-router-dom"
import { ThemeProvider } from "@material-ui/core/styles"
import { ApolloProvider } from "@apollo/client"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { client } from "./apollo-client"
import { history } from "./browser-history"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

export const AppProvider: React.FC = props => {
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DndProvider backend={HTML5Backend}>
                            {props.children}
                        </DndProvider>
                    </MuiPickersUtilsProvider>
                </Router>
            </ThemeProvider>
        </ApolloProvider>
    )
}
