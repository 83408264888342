import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { RenderCellExpand } from "../../../components/render-cell-expand"
import { Props } from "./types"

export function BankAccountOverview(props: Props): JSX.Element {
    const useStyles = makeStyles({
        root: {
            border: 0,
        },
    })

    const columns: GridColDef[] = [
        {
            field: "reference",
            headerName: "Rekeningnummer",
            flex: 1.5,
            disableColumnMenu: true,
        },
        {
            field: "holderName",
            headerName: "Naam Houder",
            flex: 1.5,
            disableColumnMenu: true,
        },
        {
            field: "balance",
            headerName: "Saldo",
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "description",
            headerName: "Beschrijving",
            flex: 3,
            disableColumnMenu: true,
            renderCell: params => RenderCellExpand(params),
        },
    ]

    return (
        <Card>
            <CardHeader title="Overzicht gekoppelde bankrekeningen" />
            <CardContent>
                <div className="flex flex-grow w-full">
                    <DataGrid
                        classes={useStyles()}
                        rows={props.accounts}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoHeight
                    />
                </div>
            </CardContent>
        </Card>
    )
}
