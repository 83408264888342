import { Button, Card, CardContent, Link, Typography } from "@material-ui/core"
import { Loading } from "../../../components/loading"
import { EditMode } from "./edit-mode/edit-mode"
import {
    initialState as initialEditModeState,
    State as EditModeState,
} from "./edit-mode/types"
import { CategoryAssignment, Props, Type } from "./types"
import { ViewMode } from "./view-mode/view-mode"

export function ExpenseCategories(props: Props): JSX.Element {
    const { state, setState, editCategoryAssignments, getCategories } = props

    function setEditModeState(editModeState: EditModeState): void {
        if (state.mode.type === Type.View) throw new Error("unreachable")
        setState({
            ...state,
            mode: {
                type: Type.Edit,
                editModeState,
            },
        })
    }

    function onSave(categoryAssignments: CategoryAssignment[]): void {
        if (state.mode.type === Type.View) throw new Error("unreachable")
        setState({
            ...state,
            loading: true,
        })
        editCategoryAssignments(categoryAssignments).then(success => {
            if (success) {
                setState({
                    categoryAssignments,
                    mode: {
                        type: Type.View,
                    },
                    loading: false,
                })
            } else {
                setState({
                    ...state,
                    mode: { type: Type.View },
                })
                alert("Er ging iets mis.")
            }
        })
    }

    function onCancel(): void {
        if (state.mode.type === Type.View) throw new Error("unreachable")
        setState({
            ...state,
            mode: {
                type: Type.View,
            },
        })
    }

    function onEditClick(): void {
        if (state.mode.type === Type.Edit) throw new Error("unreachable")
        setState({
            ...state,
            mode: {
                type: Type.Edit,
                editModeState: initialEditModeState(state.categoryAssignments),
            },
        })
    }

    function getViewModeElement(): JSX.Element {
        return state.categoryAssignments.length === 0 ? (
            <p className="text-center">
                Deze uitgave heeft nog geen categorieën, u kan er{" "}
                <Link className="cursor-pointer" onClick={onEditClick}>
                    toevoegen
                </Link>
                .
            </p>
        ) : (
            <ViewMode categoryAssignments={state.categoryAssignments} />
        )
    }

    return (
        <Card>
            {state.loading ? (
                <Loading />
            ) : (
                <div>
                    <div className="flex flex-row justify-between m-4">
                        <Typography variant="h5">
                            Niet-aftrekbare BTW: Categorieën
                        </Typography>
                        {state.mode.type === Type.View && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onEditClick}
                            >
                                WIJZIG
                            </Button>
                        )}
                    </div>
                    <CardContent>
                        {state.mode.type === Type.View ? (
                            getViewModeElement()
                        ) : (
                            <EditMode
                                state={state.mode.editModeState}
                                setState={setEditModeState}
                                onSave={onSave}
                                onCancel={onCancel}
                                getCategories={getCategories}
                            />
                        )}
                    </CardContent>
                </div>
            )}
        </Card>
    )
}
