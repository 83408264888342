export enum View {
    Expenses = "expenses",
    Bank = "bank",
    Profile = "profile",
}

export interface Props {
    selectedView: View
    setSelectedView: (newItem: View) => void
}
