import { Redirect, Route, RouteProps } from "react-router-dom"
import { isAuthenticated } from "../services/auth-service/auth.service"
import { Routes } from "../constants"

export function ProtectedRoute(routeProps: RouteProps): JSX.Element {
    if (isAuthenticated()) {
        return <Route {...routeProps} />
    } else {
        return <Redirect to={Routes.Login} />
    }
}
