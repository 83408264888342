import { TransactionOrderBy, TransactionsQuery } from "../../generated/graphql"
import { PagePayload } from "../../components/table/types"
import { TransactionRow } from "./transaction-table/types"
import { isoStringToDate } from "../../utils/date-util"

export function toRows(transactionsQuery: TransactionsQuery): TransactionRow[] {
    return transactionsQuery.transactions.edges.map(edge => ({
        id: edge.node.id,
        date: isoStringToDate(edge.node.valueDate),
        communication: edge.node.remittanceInformation,
        amount: edge.node.amount,
        recipient: edge.node.counterpartName,
    }))
}

export function toTransactionOrderBy(column: string): TransactionOrderBy {
    switch (column) {
        case "amount":
            return TransactionOrderBy.AbsAmount
        case "date":
            return TransactionOrderBy.ValueDate
        case "recipient":
            return TransactionOrderBy.CounterpartName
        default:
            throw new Error("Received an unexpected sortable column: " + column)
    }
}

export function toPagePayload(
    transactionQuery: TransactionsQuery
): PagePayload<TransactionRow> | null {
    const edgeCount = transactionQuery.transactions.pageInfo.edgeCount
    return edgeCount === null
        ? null
        : {
              rows: toRows(transactionQuery),
              startCursor: transactionQuery.transactions.pageInfo.startCursor,
              endCursor: transactionQuery.transactions.pageInfo.endCursor,
              rowCount: edgeCount,
          }
}
