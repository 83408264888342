export function formatCurrencyInEuro(value: number): string {
    return new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
    }).format(value)
}

export function formatForeignValuta(
    currency: string,
    totalForeignCurrency: number
): string {
    return new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency,
    }).format(totalForeignCurrency)
}
