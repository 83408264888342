import { useParams } from "react-router-dom"
import { Error as ErrorComponent } from "../../components/error/error"
import { Loading } from "../../components/loading"
import {
    useCategoriesQuery,
    useEditExpenseMutation,
    useExpenseQuery,
} from "../../generated/graphql"
import { Category, CategoryAssignment } from "./expense-categories/types"
import { ExpenseContainer } from "./expense-container/expense-container"
import { ExpenseOverviewInfo } from "./expense-overview/types"
import {
    toEditExpenseCategoriesInput,
    toEditExpenseOverviewInput,
    toExpenseCategories,
    toExpenseOverviewInfo,
    UrlParams,
} from "./types"

export function Expense(): JSX.Element {
    const { expenseId } = useParams<UrlParams>()

    const { refetch: fetchCategories } = useCategoriesQuery({ skip: true })

    function getCategories(): Promise<Category[] | null> {
        return fetchCategories()
            .then(result => {
                const client = result.data.client
                if (client === null) {
                    return null
                } else {
                    return client.categories.map(category => ({
                        name: category.name,
                        id: category.id,
                        code: category.code ?? "",
                    }))
                }
            })
            .catch(() => null)
    }

    const [mutateExpense] = useEditExpenseMutation({
        refetchQueries: ["Expenses"],
    })

    function editExpenseOverviewInfo(
        newExpenseOverviewInfo: ExpenseOverviewInfo
    ): Promise<boolean> {
        return mutateExpense({
            variables: {
                id: expenseId,
                editExpenseInput: toEditExpenseOverviewInput(
                    newExpenseOverviewInfo
                ),
            },
        })
            .then(() => true)
            .catch(() => false)
    }

    function editExpenseCategories(
        newAssignments: CategoryAssignment[]
    ): Promise<boolean> {
        return mutateExpense({
            variables: {
                id: expenseId,
                editExpenseInput: toEditExpenseCategoriesInput(newAssignments),
            },
        })
            .then(() => true)
            .catch(() => false)
    }

    const { loading, error, data } = useExpenseQuery({
        variables: { id: expenseId },
    })

    if (loading) return <Loading />
    if (error)
        return (
            <ErrorComponent message="We vinden de details van deze uitgave niet terug." />
        )
    if (data === undefined)
        return <ErrorComponent message="Er ging iets mis." />

    return (
        <ExpenseContainer
            mutations={{
                editExpenseOverviewInfo,
                editExpenseCategories,
            }}
            data={{
                expenseOverviewInfo: toExpenseOverviewInfo(data),
                expenseCategories: toExpenseCategories(data),
                pdfUrl: data.expense.url,
            }}
            queries={{
                getCategories,
            }}
        />
    )
}
