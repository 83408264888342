import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Fab from "@material-ui/core/Fab"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Stepper from "@material-ui/core/Stepper"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import { useReducer } from "react"
import { makeCancelable } from "../../../utils/cancelable-promise"
import { Loading } from "../../loading"
import { Dropzone } from "../dropzone/dropzone"
import { GroupPages } from "../group-pages/group-pages"
import { ActionType, reducer } from "./reducer"
import {
    ActiveStepStateType,
    initialState,
    LoadingType,
    Props,
    StateType,
} from "./types"
import { Upload } from "../group-pages/uploads-overview/types"
import { PendingExpense } from "../group-pages/pending-expenses-list/pending-expense/types"

const useStyles = makeStyles({
    paper: {
        height: "80%",
    },
})

export function UploadFilesContainer(props: Props): JSX.Element {
    const [state, dispatch] = useReducer(reducer, initialState())

    function updateSingleExpenseFiles(files: File[]): void {
        dispatch({
            type: ActionType.UPDATE_SINGLE_EXPENSE_FILES,
            payload: files,
        })
    }

    function updateMultipleExpenseFiles(files: File[]): void {
        dispatch({
            type: ActionType.UPDATE_MULTIPLE_EXPENSE_FILES,
            payload: files,
        })
    }

    function getDialogContent(): JSX.Element {
        if (state.type === StateType.Closed) {
            return <div /> // unreachable
        }
        if (state.loading.type !== LoadingType.Done) return <Loading />
        switch (state.activeStep.type) {
            case ActiveStepStateType.DropZones:
                return (
                    <div className="h-full flex flex-row space-x-4">
                        <div className="h-full flex-grow flex flex-col">
                            <div>Bestanden bestaande uit 1 factuur:</div>
                            <Dropzone
                                files={
                                    state.activeStep.dropZonesState
                                        .singleExpenseFiles
                                }
                                updateFiles={updateSingleExpenseFiles}
                            />
                        </div>
                        <div className="h-full flex-grow flex flex-col">
                            <div>
                                Bestanden bestaande uit meerdere facturen:
                            </div>
                            <Dropzone
                                files={
                                    state.activeStep.dropZonesState
                                        .multipleExpenseFiles
                                }
                                updateFiles={updateMultipleExpenseFiles}
                            />
                        </div>
                    </div>
                )
            case ActiveStepStateType.GroupPages:
                return (
                    <GroupPages
                        state={state.activeStep.groupPagesState}
                        dispatch={dispatch}
                    />
                )
        }
    }

    function nextButtonDisabled(): boolean {
        if (state.type === StateType.Closed) {
            return true
        }
        if (state.loading.type !== LoadingType.Done) return true
        switch (state.activeStep.type) {
            case ActiveStepStateType.DropZones:
                return (
                    state.activeStep.dropZonesState.multipleExpenseFiles
                        .length < 1 &&
                    state.activeStep.dropZonesState.singleExpenseFiles.length <
                        1
                )
            case ActiveStepStateType.GroupPages:
                return (
                    state.activeStep.groupPagesState.pendingExpensesState
                        .pendingExpenses.length < 1
                )
            default:
                throw new Error("unreachable")
        }
    }

    function backButtonDisabled(): boolean {
        if (state.type === StateType.Closed) {
            return true
        }
        if (state.loading.type !== LoadingType.Done) return true
        if (state.activeStep.type === ActiveStepStateType.DropZones) return true
        return false
    }

    function cancelButtonDisabled(): boolean {
        if (state.type === StateType.Closed) {
            return true
        }
        if (state.loading.type === LoadingType.Busy) return true
        return false
    }

    function toNumber(activeStep: ActiveStepStateType): number {
        switch (activeStep) {
            case ActiveStepStateType.DropZones:
                return 0
            case ActiveStepStateType.GroupPages:
                return 1
            default:
                throw new Error("unimplemented")
        }
    }

    function somethingWentWrong(): void {
        alert("Er ging iets mis.")
        dispatch({
            type: ActionType.CLOSE_STEPPER,
        })
    }

    async function handleNextClick(): Promise<void> {
        if (state.type === StateType.Closed) {
            throw new Error("unreachable")
        }
        switch (state.activeStep.type) {
            case ActiveStepStateType.DropZones: {
                const multipleExpenseFiles =
                    state.activeStep.dropZonesState.multipleExpenseFiles
                const singleExpenseFiles =
                    state.activeStep.dropZonesState.singleExpenseFiles

                const uploads: Upload[] = []
                const pendingExpenses: PendingExpense[] = []

                if (multipleExpenseFiles.length > 0) {
                    const uploadMultipleExpenseFilesCancelablePromise =
                        makeCancelable(props.uploadFiles(multipleExpenseFiles))
                    dispatch({
                        type: ActionType.START_CANCELABLE_LOADING,
                        payload: uploadMultipleExpenseFilesCancelablePromise,
                    })
                    const newUploads =
                        await uploadMultipleExpenseFilesCancelablePromise.promise
                    if (newUploads === null) {
                        somethingWentWrong()
                    } else {
                        uploads.push(...newUploads)
                    }
                }

                if (singleExpenseFiles.length > 0) {
                    const uploadSingleExpenseFilesCancelablePromise =
                        makeCancelable(props.uploadFiles(singleExpenseFiles))
                    dispatch({
                        type: ActionType.START_CANCELABLE_LOADING,
                        payload: uploadSingleExpenseFilesCancelablePromise,
                    })
                    const pendingExpenseUploads =
                        await uploadSingleExpenseFilesCancelablePromise.promise
                    if (pendingExpenseUploads === null) {
                        somethingWentWrong()
                    } else {
                        uploads.push(
                            ...pendingExpenseUploads.map(u => ({
                                ...u,
                                pages: [],
                            }))
                        )
                        pendingExpenses.push(
                            ...pendingExpenseUploads.map(u => ({
                                pages: u.pages,
                            }))
                        )
                    }
                }

                if (uploads.length === 0 && pendingExpenses.length > 0) {
                    const success = await props.createExpenses(pendingExpenses)
                    success
                        ? dispatch({ type: ActionType.CLOSE_STEPPER })
                        : somethingWentWrong()
                } else {
                    dispatch({
                        type: ActionType.UPLOAD_DONE,
                        payload: { uploads, pendingExpenses },
                    })
                }
                return
            }
            case ActiveStepStateType.GroupPages: {
                dispatch({
                    type: ActionType.START_LOADING,
                })
                props
                    .createExpenses(
                        state.activeStep.groupPagesState.pendingExpensesState
                            .pendingExpenses
                    )
                    .then(success => {
                        if (success) {
                            dispatch({
                                type: ActionType.CLOSE_STEPPER,
                            })
                        } else {
                            somethingWentWrong()
                        }
                    })
                return
            }
        }
    }

    return (
        <div>
            <Fab
                color="primary"
                onClick={() => dispatch({ type: ActionType.OPEN_STEPPER })}
            >
                <AddIcon />
            </Fab>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={state.type === StateType.Open}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick")
                        dispatch({ type: ActionType.CLOSE_STEPPER })
                }}
                classes={useStyles()}
            >
                <DialogTitle>
                    <Stepper
                        activeStep={
                            state.type === StateType.Open
                                ? toNumber(state.activeStep.type)
                                : undefined
                        }
                    >
                        <Step key={ActiveStepStateType.DropZones}>
                            <StepLabel>Upload bestanden</StepLabel>
                        </Step>
                        <Step key={ActiveStepStateType.GroupPages}>
                            <StepLabel>Groepeer bestanden</StepLabel>
                        </Step>
                    </Stepper>
                </DialogTitle>
                <DialogContent>
                    <div className="h-full">{getDialogContent()}</div>
                </DialogContent>
                <DialogActions>
                    <div className="flex flex-row flex-grow justify-between items-center">
                        <Button
                            onClick={() =>
                                dispatch({ type: ActionType.GO_BACK })
                            }
                            disabled={backButtonDisabled()}
                        >
                            TERUG
                        </Button>
                        <div className="flex flex-row items-center space-x-4">
                            <Button
                                onClick={() =>
                                    dispatch({ type: ActionType.CLOSE_STEPPER })
                                }
                                disabled={cancelButtonDisabled()}
                            >
                                ANNULEER
                            </Button>
                            <Button
                                onClick={handleNextClick}
                                disabled={nextButtonDisabled()}
                                color="primary"
                                variant="contained"
                                autoFocus
                            >
                                VOLGENDE
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
