export interface Props {
    readonly clientInfo: ClientInfo | null
}

export interface ClientInfo {
    readonly name: string
    readonly kboNumber: string
    readonly bookingPeriod: BookingPeriod
    readonly pontoClientId: string | null
    readonly pontoSecret: string | null
    readonly pontoCredentialsValid: boolean | null
}

export enum BookingPeriod {
    Monthly = "monthly",
    Quarterly = "quarterly",
}
