import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: string;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: File;
};

export type Account = {
  readonly id: Scalars['ID'];
  readonly subtype: Scalars['String'];
  readonly referenceType: Scalars['String'];
  readonly reference: Scalars['String'];
  readonly product: Scalars['String'];
  readonly holderName: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly currentBalance: Scalars['Float'];
  readonly currency: Scalars['Currency'];
  readonly availableBalance: Scalars['Float'];
  readonly authorizedAt: Scalars['Date'];
};

export enum BookingPeriod {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly'
}

export type Category = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly code: Maybe<Scalars['String']>;
};

export type CategoryAssignment = {
  readonly id: Scalars['ID'];
  readonly categoryId: Scalars['ID'];
  readonly category: Category;
  /** Amount in euro */
  readonly amount: Scalars['Float'];
  readonly expenseId: Scalars['ID'];
};

export type CategoryAssignmentInput = {
  readonly categoryId: Scalars['ID'];
  /** Amount in euro */
  readonly amount: Scalars['Float'];
};

export type Client = {
  /** The id of this client */
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly kboNumber: Scalars['String'];
  readonly bookingPeriod: BookingPeriod;
  readonly categories: ReadonlyArray<Category>;
  readonly pontoClientId: Maybe<Scalars['String']>;
  readonly pontoCredentialsValid: Maybe<Scalars['Boolean']>;
  readonly pontoSecret: Maybe<Scalars['String']>;
};

export type CreateCategoryInput = {
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type CreateExpenseInput = {
  /** This is a number between 1 and 12, which represents in which period this expense was booked. For a client that uses quarters instead of months, this is a number between 1 and 4. */
  readonly bookingPeriod?: Maybe<Scalars['Int']>;
  /** The date as mentioned in the document */
  readonly date?: Maybe<Scalars['Date']>;
  /** AF or CN */
  readonly type?: Maybe<ExpenseType>;
  /** Ordered list of pages that will make up the expense */
  readonly pageIds: ReadonlyArray<Scalars['ID']>;
};



export type DateRangeInput = {
  readonly start?: Maybe<Scalars['Date']>;
  readonly end?: Maybe<Scalars['Date']>;
};


export type EditCategoryInput = {
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly code?: Maybe<Scalars['String']>;
};

export type EditClientInput = {
  readonly pontoClientId?: Maybe<Scalars['String']>;
  readonly pontoSecret?: Maybe<Scalars['String']>;
};

export type EditExpenseInput = {
  /** This is a number between 1 and 12, which represents in which period this expense was booked. For a client that uses quarters instead of months, this is a number between 1 and 4. */
  readonly bookingPeriod?: Maybe<Scalars['Int']>;
  /** A unique identifier, in Dutch this is 'stuknummer'. */
  readonly itemId?: Maybe<Scalars['Int']>;
  /** The total amount in euro. grossAmount = netAmount + deductibleVat + nonDeductibleVat */
  readonly grossAmount?: Maybe<Scalars['Float']>;
  /** The net amount in euro */
  readonly netAmount?: Maybe<Scalars['Float']>;
  /** An amount in euro */
  readonly deductibleVat?: Maybe<Scalars['Float']>;
  /** An amount in euro. nonDeductibleVat = nonDeductibleCorporateVat + nonDeductiblePrivateVat */
  readonly nonDeductibleVat?: Maybe<Scalars['Float']>;
  /** An amount in euro  */
  readonly nonDeductibleCorporateVat?: Maybe<Scalars['Float']>;
  /** An amount in euro */
  readonly nonDeductiblePrivateVat?: Maybe<Scalars['Float']>;
  /** The deposit in euro */
  readonly deposit?: Maybe<Scalars['Float']>;
  /** The discount in euro */
  readonly discount?: Maybe<Scalars['Float']>;
  readonly currency?: Maybe<Scalars['Currency']>;
  /** The total amount in `currency` */
  readonly totalForeignCurrency?: Maybe<Scalars['Float']>;
  /** The amount of euros per unit of foreign currency */
  readonly exchangeRate?: Maybe<Scalars['Float']>;
  /** The date as mentioned in the document */
  readonly date?: Maybe<Scalars['Date']>;
  /** Just a date, usually this is just the date though. */
  readonly expirationDate?: Maybe<Scalars['Date']>;
  /** Payment information */
  readonly ogmNumber?: Maybe<Scalars['Int']>;
  /** Some free form text */
  readonly description?: Maybe<Scalars['String']>;
  /** Payment information */
  readonly reference?: Maybe<Scalars['String']>;
  readonly vatRegime?: Maybe<VatRegime>;
  /** AF or CN */
  readonly type?: Maybe<ExpenseType>;
  /** The name of the supplier */
  readonly supplier?: Maybe<Scalars['String']>;
  /** When providing a non-null argument the entire set of categories assigned to the expense will be overwritten. */
  readonly categories?: Maybe<ReadonlyArray<CategoryAssignmentInput>>;
  readonly period?: Maybe<DateRangeInput>;
  readonly transactionIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type EditTransactionInput = {
  readonly addedExpenseIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly removedExpenseIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type Expense = {
  /** A unique identifier */
  readonly id: Scalars['ID'];
  /** This is a number between 1 and 12, which represents in which period this expense was booked. For a client that uses quarters instead of months, this is a number between 1 and 4. */
  readonly bookingPeriod: Maybe<Scalars['Int']>;
  /** A unique identifier, in Dutch this is 'stuknummer'. */
  readonly itemId: Scalars['Int'];
  /** The total amount in euro. grossAmount = netAmount + deductibleVat + nonDeductibleVat */
  readonly grossAmount: Maybe<Scalars['Float']>;
  /** The net amount in euro */
  readonly netAmount: Maybe<Scalars['Float']>;
  /** An amount in euro */
  readonly deductibleVat: Maybe<Scalars['Float']>;
  /** An amount in euro. nonDeductibleVat = nonDeductibleCorporateVat + nonDeductiblePrivateVat */
  readonly nonDeductibleVat: Maybe<Scalars['Float']>;
  /** An amount in euro  */
  readonly nonDeductibleCorporateVat: Maybe<Scalars['Float']>;
  /** An amount in euro */
  readonly nonDeductiblePrivateVat: Maybe<Scalars['Float']>;
  /** The deposit in euro */
  readonly deposit: Maybe<Scalars['Float']>;
  /** The discount in euro */
  readonly discount: Maybe<Scalars['Float']>;
  readonly currency: Maybe<Scalars['Currency']>;
  /** The total amount in `currency` */
  readonly totalForeignCurrency: Maybe<Scalars['Float']>;
  /** The amount of euros per unit of foreign currency */
  readonly exchangeRate: Maybe<Scalars['Float']>;
  /** The date as mentioned in the document */
  readonly date: Maybe<Scalars['Date']>;
  /** Just a date, usually this is just the date though. */
  readonly expirationDate: Maybe<Scalars['Date']>;
  /** The date at which this expense was scanned */
  readonly scanDate: Maybe<Scalars['Date']>;
  /** Payment information */
  readonly ogmNumber: Maybe<Scalars['Int']>;
  /** Some free form text */
  readonly description: Maybe<Scalars['String']>;
  /** Payment information */
  readonly reference: Maybe<Scalars['String']>;
  readonly vatRegime: Maybe<VatRegime>;
  /** AF or CN */
  readonly type: Maybe<ExpenseType>;
  /** The name of the supplier */
  readonly supplier: Maybe<Scalars['String']>;
  readonly categoryAssignments: ReadonlyArray<CategoryAssignment>;
  readonly docString: Maybe<Scalars['String']>;
  readonly period: Maybe<PartialDateRange>;
  readonly url: Scalars['URL'];
  readonly isPaid: Scalars['Boolean'];
};

export type ExpenseConnection = {
  readonly edges: ReadonlyArray<ExpenseEdge>;
  readonly pageInfo: PageInfo;
};

export type ExpenseEdge = {
  readonly cursor: Scalars['String'];
  readonly node: Expense;
};

export enum ExpenseType {
  Af = 'AF',
  Cn = 'CN'
}

export enum ExpensesOrderBy {
  Id = 'Id',
  Date = 'Date',
  Supplier = 'Supplier',
  GrossAmount = 'GrossAmount'
}

export type Mutation = {
  readonly editExpense: Expense;
  readonly createExpenses: ReadonlyArray<Expense>;
  readonly editClient: Client;
  readonly createUploads: ReadonlyArray<Scan>;
  readonly createCategory: Category;
  readonly deleteCategory: Scalars['ID'];
  readonly editCategory: Category;
  readonly editTransaction: Transaction;
};


export type MutationEditExpenseArgs = {
  data: EditExpenseInput;
  id: Scalars['ID'];
};


export type MutationCreateExpensesArgs = {
  data: ReadonlyArray<CreateExpenseInput>;
};


export type MutationEditClientArgs = {
  data: EditClientInput;
};


export type MutationCreateUploadsArgs = {
  files: ReadonlyArray<Scalars['Upload']>;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type MutationEditCategoryArgs = {
  input: EditCategoryInput;
  categoryId: Scalars['ID'];
};


export type MutationEditTransactionArgs = {
  input: EditTransactionInput;
  transactionId: Scalars['ID'];
};

export type Page = {
  readonly id: Scalars['ID'];
  readonly uploadId: Scalars['ID'];
  readonly expensePageNumber: Scalars['Int'];
  readonly expenseId: Scalars['ID'];
  readonly url: Scalars['URL'];
};

/** PageInfo type as defined by https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  /** The cursor of the first node in edges */
  readonly startCursor: Scalars['String'];
  /** The cursor of the last node in edges */
  readonly endCursor: Scalars['String'];
  /** If the client is paginating with last/before, then the server must return true if prior edges exist, otherwise false. If the client is paginating with first/after, then the client may return true if edges prior to after exist, if it can do so efficiently, otherwise may return false */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** If the client is paginating with first/after, then the server must return true if further edges exist, otherwise false. If the client is paginating with last/before, then the client may return true if edges further from before exist, if it can do so efficiently, otherwise may return false. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Returns the total amount of edges the connection can yield. This field is nullable since it is not a part of the spec and future connections might not be able to provide it */
  readonly edgeCount: Maybe<Scalars['Int']>;
};

export type PartialDateRange = {
  readonly start: Maybe<Scalars['Date']>;
  readonly end: Maybe<Scalars['Date']>;
};

export type Query = {
  /** For forward pagination `after` and `first should be given. For backward pagination `before` and `last` should be given. Providing both `after` and `before` is undefined behaviour. If Neither is given, forward pagination is assumed */
  readonly expenses: ExpenseConnection;
  readonly expense: Expense;
  readonly client: Maybe<Client>;
  readonly listCategories: ReadonlyArray<Category>;
  readonly userInfo: User;
  readonly accounts: ReadonlyArray<Account>;
  readonly transaction: Transaction;
  readonly transactions: TransactionConnection;
};


export type QueryExpensesArgs = {
  query: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  direction?: Maybe<SortOrder>;
  orderBy?: Maybe<ExpensesOrderBy>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryExpenseArgs = {
  id: Scalars['ID'];
};


export type QueryTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryTransactionsArgs = {
  query: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  accountIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  direction?: Maybe<SortOrder>;
  orderBy?: Maybe<TransactionOrderBy>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};

export enum RemittanceInformationType {
  Structured = 'Structured',
  Unstructured = 'Unstructured'
}

export type Scan = {
  readonly id: Scalars['Float'];
  readonly clientId: Scalars['Float'];
  readonly originalName: Scalars['String'];
  readonly pages: ReadonlyArray<Page>;
  readonly scannedAt: Scalars['DateTime'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly url: Scalars['URL'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Transaction = {
  readonly id: Scalars['ID'];
  readonly accountId: Scalars['ID'];
  readonly valueDate: Scalars['Date'];
  readonly executionDate: Scalars['Date'];
  readonly remittanceInformationType: RemittanceInformationType;
  readonly remittanceInformation: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly currency: Scalars['Currency'];
  readonly counterpartReference: Maybe<Scalars['String']>;
  readonly counterpartName: Maybe<Scalars['String']>;
  readonly description: Maybe<Scalars['String']>;
  readonly additionalInformation: Maybe<Scalars['String']>;
  readonly expenses: ReadonlyArray<Expense>;
};

export type TransactionConnection = {
  readonly edges: ReadonlyArray<TransactionEdge>;
  readonly pageInfo: PageInfo;
};

export type TransactionEdge = {
  readonly cursor: Scalars['String'];
  readonly node: Transaction;
};

export enum TransactionOrderBy {
  AbsAmount = 'AbsAmount',
  ValueDate = 'ValueDate',
  CounterpartName = 'CounterpartName',
  CounterpartReference = 'CounterpartReference'
}



export type User = {
  /** A unique identifier for this user. */
  readonly id: Scalars['ID'];
  /** The first name of this user */
  readonly firstName: Scalars['String'];
  /** The last name of this user */
  readonly lastName: Scalars['String'];
  /** The email address this user uses to login. */
  readonly email: Scalars['String'];
};

export enum VatRegime {
  SubjectToTax = 'SubjectToTax',
  VatExempt = 'VatExempt',
  NoRightToDeductVat = 'NoRightToDeductVat',
  CoContractor = 'CoContractor',
  IntraCommunity = 'IntraCommunity',
  ImportOutsideEc = 'ImportOutsideEc',
  EcCocontractor = 'EcCocontractor',
  ImportOutsideEcLicensed = 'ImportOutsideEcLicensed'
}

export type UploadFilesMutationVariables = Exact<{
  files: ReadonlyArray<Scalars['Upload']> | Scalars['Upload'];
}>;


export type UploadFilesMutation = { readonly createUploads: ReadonlyArray<{ readonly id: number, readonly originalName: string, readonly pages: ReadonlyArray<{ readonly url: string, readonly id: string }> }> };

export type CreateExpensesMutationVariables = Exact<{
  input: ReadonlyArray<CreateExpenseInput> | CreateExpenseInput;
}>;


export type CreateExpensesMutation = { readonly createExpenses: ReadonlyArray<{ readonly id: string }> };

export type AccountQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountQuery = { readonly accounts: ReadonlyArray<{ readonly id: string, readonly reference: string, readonly currentBalance: number }> };

export type TransactionsQueryVariables = Exact<{
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  orderBy: Maybe<TransactionOrderBy>;
  direction: Maybe<SortOrder>;
  query: Maybe<Scalars['String']>;
  accountIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;


export type TransactionsQuery = { readonly transactions: { readonly edges: ReadonlyArray<{ readonly node: { readonly id: string, readonly counterpartName: Maybe<string>, readonly remittanceInformation: string, readonly valueDate: string, readonly amount: number } }>, readonly pageInfo: { readonly endCursor: string, readonly hasNextPage: boolean, readonly edgeCount: Maybe<number>, readonly startCursor: string } } };

export type ExpenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExpenseQuery = { readonly expense: { readonly type: Maybe<ExpenseType>, readonly docString: Maybe<string>, readonly supplier: Maybe<string>, readonly reference: Maybe<string>, readonly date: Maybe<string>, readonly expirationDate: Maybe<string>, readonly description: Maybe<string>, readonly grossAmount: Maybe<number>, readonly netAmount: Maybe<number>, readonly deductibleVat: Maybe<number>, readonly isPaid: boolean, readonly nonDeductibleCorporateVat: Maybe<number>, readonly nonDeductiblePrivateVat: Maybe<number>, readonly totalForeignCurrency: Maybe<number>, readonly currency: Maybe<string>, readonly exchangeRate: Maybe<number>, readonly discount: Maybe<number>, readonly deposit: Maybe<number>, readonly vatRegime: Maybe<VatRegime>, readonly url: string, readonly period: Maybe<{ readonly start: Maybe<string>, readonly end: Maybe<string> }>, readonly categoryAssignments: ReadonlyArray<{ readonly amount: number, readonly category: { readonly name: string, readonly id: string, readonly code: Maybe<string> } }> } };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { readonly client: Maybe<{ readonly categories: ReadonlyArray<{ readonly id: string, readonly name: string, readonly code: Maybe<string> }> }> };

export type EditExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  editExpenseInput: EditExpenseInput;
}>;


export type EditExpenseMutation = { readonly editExpense: { readonly id: string } };

export type ExpensesQueryVariables = Exact<{
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  orderBy: Maybe<ExpensesOrderBy>;
  direction: Maybe<SortOrder>;
  query: Maybe<Scalars['String']>;
}>;


export type ExpensesQuery = { readonly expenses: { readonly edges: ReadonlyArray<{ readonly node: { readonly id: string, readonly bookingPeriod: Maybe<number>, readonly type: Maybe<ExpenseType>, readonly date: Maybe<string>, readonly supplier: Maybe<string>, readonly grossAmount: Maybe<number>, readonly netAmount: Maybe<number>, readonly reference: Maybe<string>, readonly docString: Maybe<string>, readonly isPaid: boolean } }>, readonly pageInfo: { readonly endCursor: string, readonly hasNextPage: boolean, readonly edgeCount: Maybe<number>, readonly startCursor: string } } };

export type BookingPeriodQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingPeriodQuery = { readonly client: Maybe<{ readonly bookingPeriod: BookingPeriod }> };

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = { readonly userInfo: { readonly id: string, readonly firstName: string, readonly lastName: string, readonly email: string } };

export type ClientQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientQuery = { readonly client: Maybe<{ readonly name: string, readonly kboNumber: string, readonly bookingPeriod: BookingPeriod, readonly pontoClientId: Maybe<string>, readonly pontoSecret: Maybe<string>, readonly pontoCredentialsValid: Maybe<boolean>, readonly categories: ReadonlyArray<{ readonly id: string, readonly name: string, readonly code: Maybe<string>, readonly description: Maybe<string> }> }> };

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { readonly accounts: ReadonlyArray<{ readonly id: string, readonly reference: string, readonly currentBalance: number, readonly holderName: Maybe<string>, readonly description: string }> };

export type EditClientMutationVariables = Exact<{
  data: EditClientInput;
}>;


export type EditClientMutation = { readonly editClient: { readonly id: string, readonly pontoSecret: Maybe<string>, readonly pontoClientId: Maybe<string> } };

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;


export type CreateCategoryMutation = { readonly createCategory: { readonly id: string } };

export type EditCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID'];
  input: EditCategoryInput;
}>;


export type EditCategoryMutation = { readonly editCategory: { readonly id: string, readonly code: Maybe<string>, readonly name: string, readonly description: Maybe<string> } };

export type DeleteCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID'];
}>;


export type DeleteCategoryMutation = { readonly deleteCategory: string };

export type TransactionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TransactionQuery = { readonly transaction: { readonly amount: number, readonly counterpartName: Maybe<string>, readonly counterpartReference: Maybe<string>, readonly remittanceInformation: string, readonly executionDate: string, readonly valueDate: string, readonly description: Maybe<string>, readonly currency: string, readonly additionalInformation: Maybe<string>, readonly expenses: ReadonlyArray<{ readonly id: string, readonly docString: Maybe<string>, readonly supplier: Maybe<string>, readonly date: Maybe<string>, readonly grossAmount: Maybe<number>, readonly url: string }> } };

export type ExpenseSummariesQueryVariables = Exact<{
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  orderBy: Maybe<ExpensesOrderBy>;
  direction: Maybe<SortOrder>;
  query: Maybe<Scalars['String']>;
}>;


export type ExpenseSummariesQuery = { readonly expenses: { readonly edges: ReadonlyArray<{ readonly node: { readonly id: string, readonly date: Maybe<string>, readonly supplier: Maybe<string>, readonly grossAmount: Maybe<number>, readonly url: string } }>, readonly pageInfo: { readonly endCursor: string, readonly hasNextPage: boolean, readonly edgeCount: Maybe<number>, readonly startCursor: string } } };

export type AddLinkedExpensesMutationVariables = Exact<{
  transactionId: Scalars['ID'];
  addedExpenseIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;


export type AddLinkedExpensesMutation = { readonly editTransaction: { readonly id: string, readonly expenses: ReadonlyArray<{ readonly id: string, readonly docString: Maybe<string>, readonly date: Maybe<string>, readonly supplier: Maybe<string>, readonly grossAmount: Maybe<number>, readonly url: string }> } };

export type DeleteLinkedExpensesMutationVariables = Exact<{
  transactionId: Scalars['ID'];
  deletedExpenseIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;


export type DeleteLinkedExpensesMutation = { readonly editTransaction: { readonly id: string } };


export const UploadFilesDocument = gql`
    mutation UploadFiles($files: [Upload!]!) {
  createUploads(files: $files) {
    id
    originalName
    pages {
      url
      id
    }
  }
}
    `;
export type UploadFilesMutationFn = Apollo.MutationFunction<UploadFilesMutation, UploadFilesMutationVariables>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadFilesMutation(baseOptions?: Apollo.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument, options);
      }
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = Apollo.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = Apollo.BaseMutationOptions<UploadFilesMutation, UploadFilesMutationVariables>;
export const CreateExpensesDocument = gql`
    mutation CreateExpenses($input: [CreateExpenseInput!]!) {
  createExpenses(data: $input) {
    id
  }
}
    `;
export type CreateExpensesMutationFn = Apollo.MutationFunction<CreateExpensesMutation, CreateExpensesMutationVariables>;

/**
 * __useCreateExpensesMutation__
 *
 * To run a mutation, you first call `useCreateExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpensesMutation, { data, loading, error }] = useCreateExpensesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpensesMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpensesMutation, CreateExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpensesMutation, CreateExpensesMutationVariables>(CreateExpensesDocument, options);
      }
export type CreateExpensesMutationHookResult = ReturnType<typeof useCreateExpensesMutation>;
export type CreateExpensesMutationResult = Apollo.MutationResult<CreateExpensesMutation>;
export type CreateExpensesMutationOptions = Apollo.BaseMutationOptions<CreateExpensesMutation, CreateExpensesMutationVariables>;
export const AccountDocument = gql`
    query Account {
  accounts {
    id
    reference
    currentBalance
  }
}
    `;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const TransactionsDocument = gql`
    query Transactions($first: Int, $after: String, $last: Int, $before: String, $orderBy: TransactionOrderBy, $direction: SortOrder, $query: String, $accountIds: [ID!]) {
  transactions(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    direction: $direction
    query: $query
    accountIds: $accountIds
  ) {
    edges {
      node {
        id
        counterpartName
        remittanceInformation
        valueDate
        amount
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      edgeCount
      startCursor
    }
  }
}
    `;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      direction: // value for 'direction'
 *      query: // value for 'query'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const ExpenseDocument = gql`
    query Expense($id: ID!) {
  expense(id: $id) {
    type
    docString
    supplier
    reference
    date
    expirationDate
    period {
      start
      end
    }
    description
    grossAmount
    netAmount
    deductibleVat
    isPaid
    nonDeductibleCorporateVat
    nonDeductiblePrivateVat
    totalForeignCurrency
    currency
    exchangeRate
    discount
    deposit
    vatRegime
    categoryAssignments {
      category {
        name
        id
        code
      }
      amount
    }
    url
  }
}
    `;

/**
 * __useExpenseQuery__
 *
 * To run a query within a React component, call `useExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseQuery(baseOptions: Apollo.QueryHookOptions<ExpenseQuery, ExpenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseQuery, ExpenseQueryVariables>(ExpenseDocument, options);
      }
export function useExpenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseQuery, ExpenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseQuery, ExpenseQueryVariables>(ExpenseDocument, options);
        }
export type ExpenseQueryHookResult = ReturnType<typeof useExpenseQuery>;
export type ExpenseLazyQueryHookResult = ReturnType<typeof useExpenseLazyQuery>;
export type ExpenseQueryResult = Apollo.QueryResult<ExpenseQuery, ExpenseQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  client {
    categories {
      id
      name
      code
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const EditExpenseDocument = gql`
    mutation EditExpense($id: ID!, $editExpenseInput: EditExpenseInput!) {
  editExpense(id: $id, data: $editExpenseInput) {
    id
  }
}
    `;
export type EditExpenseMutationFn = Apollo.MutationFunction<EditExpenseMutation, EditExpenseMutationVariables>;

/**
 * __useEditExpenseMutation__
 *
 * To run a mutation, you first call `useEditExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExpenseMutation, { data, loading, error }] = useEditExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      editExpenseInput: // value for 'editExpenseInput'
 *   },
 * });
 */
export function useEditExpenseMutation(baseOptions?: Apollo.MutationHookOptions<EditExpenseMutation, EditExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditExpenseMutation, EditExpenseMutationVariables>(EditExpenseDocument, options);
      }
export type EditExpenseMutationHookResult = ReturnType<typeof useEditExpenseMutation>;
export type EditExpenseMutationResult = Apollo.MutationResult<EditExpenseMutation>;
export type EditExpenseMutationOptions = Apollo.BaseMutationOptions<EditExpenseMutation, EditExpenseMutationVariables>;
export const ExpensesDocument = gql`
    query Expenses($first: Int, $after: String, $last: Int, $before: String, $orderBy: ExpensesOrderBy, $direction: SortOrder, $query: String) {
  expenses(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    direction: $direction
    query: $query
  ) {
    edges {
      node {
        id
        bookingPeriod
        type
        date
        supplier
        grossAmount
        netAmount
        reference
        docString
        isPaid
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      edgeCount
      startCursor
    }
  }
}
    `;

/**
 * __useExpensesQuery__
 *
 * To run a query within a React component, call `useExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      direction: // value for 'direction'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useExpensesQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
      }
export function useExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
        }
export type ExpensesQueryHookResult = ReturnType<typeof useExpensesQuery>;
export type ExpensesLazyQueryHookResult = ReturnType<typeof useExpensesLazyQuery>;
export type ExpensesQueryResult = Apollo.QueryResult<ExpensesQuery, ExpensesQueryVariables>;
export const BookingPeriodDocument = gql`
    query BookingPeriod {
  client {
    bookingPeriod
  }
}
    `;

/**
 * __useBookingPeriodQuery__
 *
 * To run a query within a React component, call `useBookingPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingPeriodQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingPeriodQuery(baseOptions?: Apollo.QueryHookOptions<BookingPeriodQuery, BookingPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingPeriodQuery, BookingPeriodQueryVariables>(BookingPeriodDocument, options);
      }
export function useBookingPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingPeriodQuery, BookingPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingPeriodQuery, BookingPeriodQueryVariables>(BookingPeriodDocument, options);
        }
export type BookingPeriodQueryHookResult = ReturnType<typeof useBookingPeriodQuery>;
export type BookingPeriodLazyQueryHookResult = ReturnType<typeof useBookingPeriodLazyQuery>;
export type BookingPeriodQueryResult = Apollo.QueryResult<BookingPeriodQuery, BookingPeriodQueryVariables>;
export const UserInfoDocument = gql`
    query userInfo {
  userInfo {
    id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const ClientDocument = gql`
    query client {
  client {
    name
    kboNumber
    bookingPeriod
    pontoClientId
    pontoSecret
    pontoCredentialsValid
    categories {
      id
      name
      code
      description
    }
  }
}
    `;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientQuery(baseOptions?: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const AccountsDocument = gql`
    query accounts {
  accounts {
    id
    reference
    currentBalance
    holderName
    description
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const EditClientDocument = gql`
    mutation editClient($data: EditClientInput!) {
  editClient(data: $data) {
    id
    pontoSecret
    pontoClientId
  }
}
    `;
export type EditClientMutationFn = Apollo.MutationFunction<EditClientMutation, EditClientMutationVariables>;

/**
 * __useEditClientMutation__
 *
 * To run a mutation, you first call `useEditClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClientMutation, { data, loading, error }] = useEditClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditClientMutation(baseOptions?: Apollo.MutationHookOptions<EditClientMutation, EditClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditClientMutation, EditClientMutationVariables>(EditClientDocument, options);
      }
export type EditClientMutationHookResult = ReturnType<typeof useEditClientMutation>;
export type EditClientMutationResult = Apollo.MutationResult<EditClientMutation>;
export type EditClientMutationOptions = Apollo.BaseMutationOptions<EditClientMutation, EditClientMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    id
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const EditCategoryDocument = gql`
    mutation EditCategory($categoryId: ID!, $input: EditCategoryInput!) {
  editCategory(categoryId: $categoryId, input: $input) {
    id
    code
    name
    description
  }
}
    `;
export type EditCategoryMutationFn = Apollo.MutationFunction<EditCategoryMutation, EditCategoryMutationVariables>;

/**
 * __useEditCategoryMutation__
 *
 * To run a mutation, you first call `useEditCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCategoryMutation, { data, loading, error }] = useEditCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditCategoryMutation, EditCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCategoryMutation, EditCategoryMutationVariables>(EditCategoryDocument, options);
      }
export type EditCategoryMutationHookResult = ReturnType<typeof useEditCategoryMutation>;
export type EditCategoryMutationResult = Apollo.MutationResult<EditCategoryMutation>;
export type EditCategoryMutationOptions = Apollo.BaseMutationOptions<EditCategoryMutation, EditCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($categoryId: ID!) {
  deleteCategory(categoryId: $categoryId)
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const TransactionDocument = gql`
    query Transaction($id: ID!) {
  transaction(id: $id) {
    amount
    counterpartName
    counterpartReference
    remittanceInformation
    executionDate
    valueDate
    description
    currency
    additionalInformation
    expenses {
      id
      docString
      supplier
      date
      grossAmount
      url
    }
  }
}
    `;

/**
 * __useTransactionQuery__
 *
 * To run a query within a React component, call `useTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionQuery(baseOptions: Apollo.QueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, options);
      }
export function useTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, options);
        }
export type TransactionQueryHookResult = ReturnType<typeof useTransactionQuery>;
export type TransactionLazyQueryHookResult = ReturnType<typeof useTransactionLazyQuery>;
export type TransactionQueryResult = Apollo.QueryResult<TransactionQuery, TransactionQueryVariables>;
export const ExpenseSummariesDocument = gql`
    query ExpenseSummaries($first: Int, $after: String, $last: Int, $before: String, $orderBy: ExpensesOrderBy, $direction: SortOrder, $query: String) {
  expenses(
    first: $first
    after: $after
    last: $last
    before: $before
    orderBy: $orderBy
    direction: $direction
    query: $query
  ) {
    edges {
      node {
        id
        date
        supplier
        grossAmount
        url
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      edgeCount
      startCursor
    }
  }
}
    `;

/**
 * __useExpenseSummariesQuery__
 *
 * To run a query within a React component, call `useExpenseSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseSummariesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      direction: // value for 'direction'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useExpenseSummariesQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseSummariesQuery, ExpenseSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseSummariesQuery, ExpenseSummariesQueryVariables>(ExpenseSummariesDocument, options);
      }
export function useExpenseSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseSummariesQuery, ExpenseSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseSummariesQuery, ExpenseSummariesQueryVariables>(ExpenseSummariesDocument, options);
        }
export type ExpenseSummariesQueryHookResult = ReturnType<typeof useExpenseSummariesQuery>;
export type ExpenseSummariesLazyQueryHookResult = ReturnType<typeof useExpenseSummariesLazyQuery>;
export type ExpenseSummariesQueryResult = Apollo.QueryResult<ExpenseSummariesQuery, ExpenseSummariesQueryVariables>;
export const AddLinkedExpensesDocument = gql`
    mutation addLinkedExpenses($transactionId: ID!, $addedExpenseIds: [ID!]) {
  editTransaction(
    transactionId: $transactionId
    input: {addedExpenseIds: $addedExpenseIds}
  ) {
    id
    expenses {
      id
      docString
      date
      supplier
      grossAmount
      url
    }
  }
}
    `;
export type AddLinkedExpensesMutationFn = Apollo.MutationFunction<AddLinkedExpensesMutation, AddLinkedExpensesMutationVariables>;

/**
 * __useAddLinkedExpensesMutation__
 *
 * To run a mutation, you first call `useAddLinkedExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLinkedExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLinkedExpensesMutation, { data, loading, error }] = useAddLinkedExpensesMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      addedExpenseIds: // value for 'addedExpenseIds'
 *   },
 * });
 */
export function useAddLinkedExpensesMutation(baseOptions?: Apollo.MutationHookOptions<AddLinkedExpensesMutation, AddLinkedExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLinkedExpensesMutation, AddLinkedExpensesMutationVariables>(AddLinkedExpensesDocument, options);
      }
export type AddLinkedExpensesMutationHookResult = ReturnType<typeof useAddLinkedExpensesMutation>;
export type AddLinkedExpensesMutationResult = Apollo.MutationResult<AddLinkedExpensesMutation>;
export type AddLinkedExpensesMutationOptions = Apollo.BaseMutationOptions<AddLinkedExpensesMutation, AddLinkedExpensesMutationVariables>;
export const DeleteLinkedExpensesDocument = gql`
    mutation deleteLinkedExpenses($transactionId: ID!, $deletedExpenseIds: [ID!]) {
  editTransaction(
    transactionId: $transactionId
    input: {removedExpenseIds: $deletedExpenseIds}
  ) {
    id
  }
}
    `;
export type DeleteLinkedExpensesMutationFn = Apollo.MutationFunction<DeleteLinkedExpensesMutation, DeleteLinkedExpensesMutationVariables>;

/**
 * __useDeleteLinkedExpensesMutation__
 *
 * To run a mutation, you first call `useDeleteLinkedExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkedExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkedExpensesMutation, { data, loading, error }] = useDeleteLinkedExpensesMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      deletedExpenseIds: // value for 'deletedExpenseIds'
 *   },
 * });
 */
export function useDeleteLinkedExpensesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLinkedExpensesMutation, DeleteLinkedExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLinkedExpensesMutation, DeleteLinkedExpensesMutationVariables>(DeleteLinkedExpensesDocument, options);
      }
export type DeleteLinkedExpensesMutationHookResult = ReturnType<typeof useDeleteLinkedExpensesMutation>;
export type DeleteLinkedExpensesMutationResult = Apollo.MutationResult<DeleteLinkedExpensesMutation>;
export type DeleteLinkedExpensesMutationOptions = Apollo.BaseMutationOptions<DeleteLinkedExpensesMutation, DeleteLinkedExpensesMutationVariables>;