import { Props } from "./types"
import { formatCurrencyInEuro } from "../../../../../utils/currency-util"
import { applyFormatter } from "../../../../../utils/util"

export function NonDeductibleVatDetail(props: Props): JSX.Element {
    return (
        <div className="flex flex-col">
            <div>
                <b>Niet-aftrekbare BTW</b>
            </div>
            <div>
                <span>Privé: </span>
                {props.private === null ? (
                    <span className="text-secondary"> Ongekend</span>
                ) : (
                    <span className="font-serif">
                        {applyFormatter(formatCurrencyInEuro, props.private)}
                    </span>
                )}
            </div>
            <div>
                <span>Bedrijf: </span>
                {props.corporate === null ? (
                    <span className="text-secondary"> Ongekend</span>
                ) : (
                    <span className="font-serif">
                        {applyFormatter(formatCurrencyInEuro, props.corporate)}
                    </span>
                )}
            </div>
        </div>
    )
}
