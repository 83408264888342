import {
    GetInitialPages,
    PageBackwards,
    PageForwards,
    PagePayload,
    State as TableState,
} from "../../../components/table/types"

export const INITIAL_PAGE_SIZE = 5
export const INITIAL_SORT_BY = "id"

export interface LinkedExpenseRow {
    readonly id: string
    readonly selected: boolean
    readonly date: Date | null
    readonly supplier: string | null
    readonly grossAmount: number | null
    readonly url: string
}

export type State = LoadedState | UnloadedState | ErrorState

export enum Type {
    Loaded = "loaded",
    Unloaded = "unloaded",
    Error = "error",
}

export interface LoadedState {
    type: Type.Loaded
    tableState: TableState<LinkedExpenseRow>
}

export interface UnloadedState {
    type: Type.Unloaded
}

export interface ErrorState {
    type: Type.Error
}

export interface Props {
    readonly state: State
    readonly setState: (state: State) => void
    readonly selectedExpenseIds: string[]
    readonly previouslyLinkedExpenseIds: string[]
    readonly setSelectedExpenseIds: (ids: string[]) => void
    readonly setSelectedExpensePDFUrl: (url: string | null) => void
    readonly pageBackwards: PageBackwards<PagePayload<LinkedExpenseRow>>
    readonly pageForwards: PageForwards<PagePayload<LinkedExpenseRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<LinkedExpenseRow>>
}

export function initialState(): State {
    return {
        type: Type.Unloaded,
    }
}
