import { Card, CardContent, CardHeader } from "@material-ui/core"
import { Props } from "./types"
import { Detail } from "../../../components/detail/detail"
import { applyFormatter } from "../../../utils/util"
import { formatCurrencyInEuro } from "../../../utils/currency-util"
import { formatDate } from "../../../utils/date-util"

export function TransactionOverview(props: Props): JSX.Element {
    const { transactionOverview } = props
    return (
        <Card>
            <CardHeader title="Overzicht transactie" />
            <CardContent>
                <div className=" grid grid-cols-4 gap-9">
                    <Detail
                        label="Bedrag"
                        currency
                        value={applyFormatter(
                            formatCurrencyInEuro,
                            transactionOverview.amount
                        )}
                    />
                    <Detail
                        label="Naam ontvanger "
                        value={transactionOverview.counterpartName}
                    />
                    <Detail
                        label="Rekeningnummer ontvanger "
                        value={transactionOverview.counterpartReference}
                    />
                    <Detail
                        label="Mededeling"
                        value={transactionOverview.remittanceInformation}
                    />
                    <Detail
                        label="Datum uitvoering"
                        value={applyFormatter(
                            formatDate,
                            transactionOverview.executionDate
                        )}
                    />
                    <Detail
                        label="Afwikkelingsdatum"
                        value={applyFormatter(
                            formatDate,
                            transactionOverview.valueDate
                        )}
                    />
                    <Detail
                        label="Bescrhijving"
                        value={transactionOverview.description}
                    />
                    <Detail
                        label="Bescrhijving"
                        value={transactionOverview.description}
                    />
                    <Detail
                        label="Munteenheid"
                        value={transactionOverview.currency}
                    />
                    <Detail
                        label="Overige informatie"
                        value={transactionOverview.additionalInformation}
                    />
                </div>
            </CardContent>
        </Card>
    )
}
