import { EditExpenseInput, ExpenseQuery } from "../../generated/graphql"
import { formatDateToIsoString, isoStringToDate } from "../../utils/date-util"
import { applyFormatter } from "../../utils/util"
import { CategoryAssignment } from "./expense-categories/types"
import { ExpenseOverviewInfo } from "./expense-overview/types"

export interface UrlParams {
    readonly expenseId: string
}

export function toExpenseOverviewInfo(
    expenseQuery: ExpenseQuery
): ExpenseOverviewInfo {
    const expense = expenseQuery.expense
    return {
        type: expense.type,
        supplier: expense.supplier,
        reference: expense.reference,
        date: isoStringToDate(expense.date),
        expirationDate: isoStringToDate(expense.expirationDate),
        periodStart:
            expense.period === null
                ? null
                : isoStringToDate(expense.period.start),
        periodEnd:
            expense.period === null
                ? null
                : isoStringToDate(expense.period.end),
        description: expense.description,
        grossAmount: expense.grossAmount,
        netAmount: expense.netAmount,
        deductibleVat: expense.deductibleVat,
        nonDeductiblePrivateVat: expense.nonDeductiblePrivateVat,
        nonDeductibleCorporateVat: expense.nonDeductibleCorporateVat,
        currency: expense.currency,
        totalForeignCurrency: expense.totalForeignCurrency,
        exchangeRate: expense.exchangeRate,
        discount: expense.discount,
        deposit: expense.deposit,
        vatRegime: expense.vatRegime,
        docString: expense.docString,
        isPaid: expense.isPaid,
    }
}

export function toEditExpenseOverviewInput(
    expenseDetails: ExpenseOverviewInfo
): EditExpenseInput {
    return {
        type: expenseDetails.type,
        supplier: expenseDetails.supplier,
        reference: expenseDetails.reference,
        description: expenseDetails.description,
        date: applyFormatter(formatDateToIsoString, expenseDetails.date),
        expirationDate: applyFormatter(
            formatDateToIsoString,
            expenseDetails.expirationDate
        ),
        netAmount: expenseDetails.netAmount,
        deductibleVat: expenseDetails.deductibleVat,
        nonDeductibleCorporateVat: expenseDetails.nonDeductibleCorporateVat,
        nonDeductiblePrivateVat: expenseDetails.nonDeductiblePrivateVat,
        totalForeignCurrency: expenseDetails.totalForeignCurrency,
        currency: expenseDetails.currency,
        exchangeRate: expenseDetails.exchangeRate,
        grossAmount: expenseDetails.grossAmount,
        discount: expenseDetails.discount,
        deposit: expenseDetails.deposit,
        period: {
            start: applyFormatter(
                formatDateToIsoString,
                expenseDetails.periodStart
            ),
            end: applyFormatter(
                formatDateToIsoString,
                expenseDetails.periodEnd
            ),
        },
        vatRegime: expenseDetails.vatRegime,
    }
}

export function toExpenseCategories(
    expenseQuery: ExpenseQuery
): CategoryAssignment[] {
    return expenseQuery.expense.categoryAssignments.map(assignment => ({
        category: {
            id: assignment.category.id,
            name: assignment.category.name,
            code: assignment.category.code ?? "",
        },
        amount: assignment.amount,
    }))
}

export function toEditExpenseCategoriesInput(
    pendingAssignments: CategoryAssignment[]
): EditExpenseInput {
    return {
        categories: pendingAssignments.map(assignment => ({
            categoryId: assignment.category.id,
            amount: assignment.amount,
        })),
    }
}
