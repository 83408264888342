import { useState } from "react"
import { ExpenseCategories } from "../expense-categories/expense-categories"
import { State as ExpenseCategoriesState } from "../expense-categories/types"
import { ExpenseOverview } from "../expense-overview/expense-overview"
import { State as ExpenseOverViewState } from "../expense-overview/types"
import { ExpensePDF } from "../expense-pdf/expense-pdf"
import { getInitialState, Props, State } from "./types"

export function ExpenseContainer(props: Props): JSX.Element {
    const [state, setState] = useState<State>(getInitialState(props.data))

    function setExpenseOverviewState(
        expenseOverViewState: ExpenseOverViewState
    ): void {
        setState({
            ...state,
            expenseOverViewState,
        })
    }

    function setExpenseCategoriesState(
        expenseCategoriesState: ExpenseCategoriesState
    ): void {
        setState({
            ...state,
            expenseCategoriesState,
        })
    }

    return (
        <div className="flex flex-col h-full space-y-4">
            <div className="flex flex-row w-full flex-grow min-h-0">
                <div className="w-5/12 h-full overflow-scroll">
                    <div className="flex flex-col space-y-4">
                        <ExpenseOverview
                            state={state.expenseOverViewState}
                            setState={setExpenseOverviewState}
                            editExpenseOverViewInfo={
                                props.mutations.editExpenseOverviewInfo
                            }
                        />
                        <ExpenseCategories
                            state={state.expenseCategoriesState}
                            setState={setExpenseCategoriesState}
                            editCategoryAssignments={
                                props.mutations.editExpenseCategories
                            }
                            getCategories={props.queries.getCategories}
                        />
                    </div>
                </div>
                <div className="w-7/12 h-full">
                    <ExpensePDF url={props.data.pdfUrl} />
                </div>
            </div>
        </div>
    )
}
