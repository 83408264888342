import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@material-ui/core"
import React from "react"
import { useCreateCategoryMutation } from "../../../generated/graphql"

export function CreateNewCategory(): JSX.Element {
    const [createCategory] = useCreateCategoryMutation({
        refetchQueries: ["client"],
    })

    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [code, setCode] = React.useState("")
    const [name, setName] = React.useState("")
    const [description, setDescription] = React.useState("")

    function close(): void {
        if (loading) return
        setCode("")
        setName("")
        setDescription("")
        setOpen(false)
    }

    function save(): void {
        setLoading(true)
        createCategory({
            variables: {
                input: {
                    name,
                    code,
                    description,
                },
            },
        }).then(result => {
            setLoading(false)
            if (!result.data) alert("Er ging iets mis.")
            close()
        })
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
            >
                NIEUWE CATEGORIE
            </Button>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Maak nieuwe categorie aan</DialogTitle>
                <DialogContent>
                    <div className="flex flex-col space-y-4 m-4 w-64">
                        <TextField
                            label="Code"
                            variant="outlined"
                            color="primary"
                            onChange={e => {
                                setCode(e.target.value)
                            }}
                            value={code}
                        />
                        <TextField
                            label="Naam"
                            variant="outlined"
                            color="primary"
                            onChange={e => {
                                setName(e.target.value)
                            }}
                            value={name}
                        />
                        <TextField
                            label="Beschrijving"
                            variant="outlined"
                            color="primary"
                            multiline
                            rows={4}
                            onChange={e => {
                                setDescription(e.target.value)
                            }}
                            value={description}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} disabled={loading}>
                        ANNULEER
                    </Button>
                    <Button variant="contained" color="primary" onClick={save}>
                        MAAK AAN
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
