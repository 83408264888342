import { format } from "date-fns"

export function formatDate(date: Date): string {
    return format(date, "dd/MM/yyyy")
}

export function isoStringToDate(dateString: string | null): Date | null {
    if (dateString === null) return null
    const date = new Date(dateString)
    if (isNaN(date.valueOf())) return null
    return date
}

export function formatDateToIsoString(date: Date): string {
    return format(date, "yyyy-MM-dd")
}
