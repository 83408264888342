import { initialState, Props, State } from "./types"
import { ExpenseTable } from "../expense-table/expense-table"
import { useState } from "react"
import { State as ExpenseTableState } from "../expense-table/types"

export function ExpensesContainer(props: Props): JSX.Element {
    const [state, setState] = useState<State>(
        initialState(
            props.data.rows,
            props.data.rowCount,
            props.data.startCursor,
            props.data.endCursor
        )
    )

    function setExpenseTableState(expenseTableState: ExpenseTableState): void {
        setState({ expenseTableState })
    }

    return (
        <div className="w-full h-full overflow-y-scroll">
            <ExpenseTable
                state={state.expenseTableState}
                setState={setExpenseTableState}
                bookingPeriod={props.data.bookingPeriod}
                pageBackwards={props.queries.pageBackwards}
                pageForwards={props.queries.pageForwards}
                getInitialPages={props.queries.getInitialPages}
            />
        </div>
    )
}
