import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined"
import { DropzoneArea } from "material-ui-dropzone"
import { ReactElement } from "react"
import { Props } from "./types"

function getFileRejectedMessage(
    rejectedFile: File,
    maxFileSize: number
): string {
    if (
        rejectedFile.type !== "application/pdf" &&
        rejectedFile.type !== "image/jpeg" &&
        rejectedFile.type !== "image/tiff"
    )
        return `Bestandstype van ${rejectedFile.name} is niet ondersteund. Toegelaten types zijn PDF, JPEG en TIFF.`
    else if (rejectedFile.size > maxFileSize)
        return `Bestand ${rejectedFile.name} is te groot. De maximale bestandsgroote is ${maxFileSize} bytes.`
    else return `Bestand ${rejectedFile.name} is gewijgerd.`
}

export function Dropzone(props: Props): JSX.Element {
    const { files, updateFiles } = props
    return (
        <div className="w-full h-full py-8">
            <DropzoneArea
                classes={{
                    textContainer:
                        "h-5/6 flex flex-col items-center justify-center space-y-4",
                }}
                dropzoneText="Sleep een bestand of klik hier."
                dropzoneClass="h-full flex flex-col justify-between"
                Icon={CloudUploadOutlinedIcon as unknown as ReactElement}
                useChipsForPreview
                previewGridClasses={{ container: "p-4" }}
                acceptedFiles={["application/pdf", "image/jpeg", "image/tiff"]}
                filesLimit={50}
                maxFileSize={50000000} // 50 MB
                showAlerts={["error"]}
                alertSnackbarProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                }}
                getFileLimitExceedMessage={filesLimit =>
                    `Maximaal ${filesLimit} bestanden toegelaten.`
                }
                getDropRejectMessage={(rejectedFile, _, maxFileSize) =>
                    getFileRejectedMessage(rejectedFile, maxFileSize)
                }
                initialFiles={files}
                onChange={updateFiles}
            />
        </div>
    )
}
