import { Route, Switch } from "react-router-dom"
import { Routes } from "./constants"
import { Login } from "./views/login/login"
import { Container } from "./layout/container/container"
import { ProtectedRoute } from "./components/protected-route"
import { CssBaseline } from "@material-ui/core"
import { AppProvider } from "./providers/AppProvider"

export function App(): JSX.Element {
    return (
        <AppProvider>
            <CssBaseline />
            <Switch>
                <Route exact path={Routes.Login} component={Login} />
                <ProtectedRoute path={Routes.Root} component={Container} />
            </Switch>
        </AppProvider>
    )
}
