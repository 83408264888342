import { Button, IconButton, TextField } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { Autocomplete } from "@material-ui/lab"
import { useEffect } from "react"
import { CurrencyInput } from "../../../../components/currency-input/currency-input"
import { Category, CategoryAssignment } from "../types"
import { Props } from "./types"

export function EditMode(props: Props): JSX.Element {
    const { state, setState, onSave, onCancel, getCategories } = props

    useEffect(() => {
        if (state.categoryOptions.length === 0 && !state.loadingCategories) {
            setState({
                ...state,
                loadingCategories: true,
            })
            getCategories().then(result => {
                if (result === null) {
                    alert("Er ging iets mis.")
                } else {
                    setState({
                        ...state,
                        loadingCategories: false,
                        categoryOptions: result,
                    })
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onCategoryChange(
        category: Category | null,
        indexToChange: number
    ): void {
        const pendingCategoryAssignments = state.pendingCategoryAssignments.map(
            (assignment, index) =>
                index === indexToChange
                    ? {
                          ...assignment,
                          category,
                      }
                    : assignment
        )
        setState({
            ...state,
            pendingCategoryAssignments,
        })
    }

    function onAmountChange(
        amount: number | null,
        indexToChange: number
    ): void {
        const pendingCategoryAssignments = state.pendingCategoryAssignments.map(
            (assignment, index) =>
                index === indexToChange
                    ? {
                          ...assignment,
                          amount,
                      }
                    : assignment
        )
        setState({
            ...state,
            pendingCategoryAssignments,
        })
    }

    function onSplitExpenseClick(): void {
        setState({
            ...state,
            pendingCategoryAssignments: [
                ...state.pendingCategoryAssignments,
                { category: null, amount: null },
            ],
        })
    }

    function onRemoveClick(indexToRemove: number): void {
        const pendingCategoryAssignments =
            state.pendingCategoryAssignments.filter(
                (_, index) => index !== indexToRemove
            )
        setState({
            ...state,
            pendingCategoryAssignments,
        })
    }

    function saveDisabled(): boolean {
        return state.pendingCategoryAssignments.some(
            assignment =>
                assignment.amount === null || assignment.category === null
        )
    }

    return (
        <div>
            <div className="flex flex-col space-y-4 items-start">
                {state.pendingCategoryAssignments.map((assignment, index) => (
                    <div className="flex flex-row space-x-4 w-full">
                        <Autocomplete
                            loading={state.loadingCategories}
                            className="flex-grow"
                            value={assignment.category}
                            options={state.categoryOptions}
                            getOptionLabel={option =>
                                `${option.code} : ${option.name}`
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Categorie"
                                    variant="outlined"
                                />
                            )}
                            onChange={(_, value) =>
                                onCategoryChange(value, index)
                            }
                        />
                        <CurrencyInput
                            className="flex-grow"
                            label={"Bedrag"}
                            value={assignment.amount}
                            onChange={amount => onAmountChange(amount, index)}
                        />
                        <IconButton onClick={() => onRemoveClick(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
                <div className="flex flex-row space-x-4">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onSplitExpenseClick}
                    >
                        SPLITS UITGAVE
                    </Button>
                </div>
                <div className="flex flex-row space-x-4 justify-end w-full">
                    <Button onClick={onCancel}>ANNULEER</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={saveDisabled()}
                        onClick={() =>
                            onSave(
                                // Save can only be clicked if none of the categories contain null fields.
                                state.pendingCategoryAssignments as CategoryAssignment[]
                            )
                        }
                    >
                        OPSLAAN
                    </Button>
                </div>
            </div>
        </div>
    )
}
