import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from "@material-ui/core"
import EditRoundedIcon from "@material-ui/icons/EditRounded"
import React from "react"
import { useEditCategoryMutation } from "../../../../generated/graphql"
import { Props } from "./types"

export function EditCategory(props: Props): JSX.Element {
    const [editCategory] = useEditCategoryMutation({
        refetchQueries: ["client"],
    })

    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [code, setCode] = React.useState(props.category.code)
    const [name, setName] = React.useState(props.category.name)
    const [description, setDescription] = React.useState(
        props.category.description
    )

    function close(): void {
        if (loading) return
        setCode(props.category.code)
        setName(props.category.name)
        setDescription(props.category.description)
        setOpen(false)
    }

    function save(): void {
        setLoading(true)
        editCategory({
            variables: {
                categoryId: props.category.id,
                input: {
                    name,
                    code,
                    description,
                },
            },
        }).then(result => {
            setLoading(false)
            if (!result.data) {
                alert("Er ging iets mis.")
                close()
            } else {
                setCode(result.data.editCategory.code ?? "")
                setName(result.data.editCategory.name)
                setDescription(result.data.editCategory.description ?? "")
                setOpen(false)
            }
        })
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <EditRoundedIcon />
            </IconButton>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Wijzig categorie</DialogTitle>
                <DialogContent>
                    <div className="flex flex-col space-y-4 m-4 w-64">
                        <TextField
                            label="Code"
                            variant="outlined"
                            color="primary"
                            onChange={e => {
                                setCode(e.target.value)
                            }}
                            value={code}
                        />
                        <TextField
                            label="Naam"
                            variant="outlined"
                            color="primary"
                            onChange={e => {
                                setName(e.target.value)
                            }}
                            value={name}
                        />
                        <TextField
                            label="Beschrijving"
                            variant="outlined"
                            color="primary"
                            multiline
                            rows={4}
                            onChange={e => {
                                setDescription(e.target.value)
                            }}
                            value={description}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} disabled={loading}>
                        ANNULEER
                    </Button>
                    <Button variant="contained" color="primary" onClick={save}>
                        WIJZIG
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
