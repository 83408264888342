import { Props } from "./types"
import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

export function NavRailItem(props: Props): JSX.Element {
    const theme = useTheme()
    const style = {
        color: props.selected
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
    }
    return (
        <div
            className="flex flex-col items-center rounded-lg hover:bg-surface p-2 space-y-1 cursor-pointer"
            onClick={props.onClick}
        >
            <span className="material-icons-outlined text-3xl" style={style}>
                {props.icon}
            </span>
            <Typography
                variant="body1"
                color={props.selected ? "textPrimary" : "textSecondary"}
            >
                <b>{props.label}</b>
            </Typography>
        </div>
    )
}
