/** code retrieved from https://codesandbox.io/s/expandcelldemo-enbw7?file=/src/renderCellExpand.tsx:200-222 */

import { Paper, Popper } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { GridRenderCellParams } from "@mui/x-data-grid"
import * as React from "react"

interface CellExpandProps {
    value: string
    width: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: "center",
            lineHeight: "24px",
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            "& .MuiRating-root": {
                marginRight: theme.spacing(1),
            },
            "& .cellValue": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    })
)
const CellExpand = React.memo(function CellExpand(props: CellExpandProps) {
    const { width, value } = props
    const wrapper = React.useRef<HTMLDivElement | null>(null)
    const cellDiv = React.useRef(null)
    const cellValue = React.useRef<HTMLDivElement | null>(null)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const classes = useStyles()
    const [showFullCell, setShowFullCell] = React.useState(false)
    const [showPopper, setShowPopper] = React.useState(false)

    const showCell = React.useCallback(() => {
        setShowFullCell(true)
    }, [])
    const hideCell = React.useCallback(() => {
        setShowFullCell(false)
    }, [])

    React.useEffect(() => {
        if (cellDiv.current) {
            setAnchorEl(cellDiv.current)
        }
    }, [])
    React.useEffect(() => {
        if (cellValue && cellValue.current) {
            if (!cellValue.current) return
            const isCurrentlyOverflown =
                cellValue.current.scrollWidth > cellValue.current!.clientWidth
            setShowPopper(isCurrentlyOverflown)
        }
    }, [width])

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={showCell}
            onMouseLeave={hideCell}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper && (
                <Popper
                    id={"123"}
                    open={showFullCell && anchorEl != null}
                    anchorEl={anchorEl}
                    style={{ width: 250, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 2 }}
                    >
                        <div style={{ padding: 5 }}>{value}</div>
                    </Paper>
                </Popper>
            )}
        </div>
    )
})
export function RenderCellExpand(params: GridRenderCellParams): JSX.Element {
    return (
        <CellExpand
            value={params.value ? params.value.toString() : ""}
            width={500}
        />
    )
}
