import { TransactionOverview } from "../transaction-overview/types"
import { State as EditLinkedExpensesDialogState } from "../edit-linked-expenses-dialog/types"
import { initialState as initialEditLinkedExpensesDialogState } from "../edit-linked-expenses-dialog/types"
import {
    GetInitialPages,
    PageBackwards,
    PageForwards,
    PagePayload,
} from "../../../components/table/types"
import { LinkedExpenseRow } from "../add-linked-expenses-table/types"

export interface Props {
    readonly state: State
    readonly setState: (state: State) => void
    readonly transactionOverview: TransactionOverview
    readonly pageBackwards: PageBackwards<PagePayload<LinkedExpenseRow>>
    readonly pageForwards: PageForwards<PagePayload<LinkedExpenseRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<LinkedExpenseRow>>
    readonly deleteLinkedExpense: (id: string[]) => Promise<boolean>
    readonly addLinkedExpenses: (
        id: string[]
    ) => Promise<{ success: boolean; expenses: ExpenseSummary[] }>
}

export interface State {
    readonly editLinkedExpensesDialogState: EditLinkedExpensesDialogState
    readonly linkedExpenses: readonly ExpenseSummary[]
    readonly selectedExpense: ExpenseSummary | null
    readonly dialogOpen: boolean
}

export interface ExpenseSummary {
    readonly id: string
    readonly docString: string | null
    readonly supplier: string | null
    readonly grossAmount: number | null
    readonly date: Date | null
    readonly url: string
}

export function initialState(linkedExpenses: readonly ExpenseSummary[]): State {
    if (linkedExpenses.length === 0)
        return {
            linkedExpenses,
            selectedExpense: null,
            dialogOpen: false,
            editLinkedExpensesDialogState:
                initialEditLinkedExpensesDialogState(),
        }
    return {
        linkedExpenses,
        selectedExpense: linkedExpenses[0],
        dialogOpen: false,
        editLinkedExpensesDialogState: initialEditLinkedExpensesDialogState(),
    }
}
