export function applyFormatter<T1, T2>(
    formatter: (x: T1) => T2,
    value: T1 | null
): T2 | null {
    return value === null ? null : formatter(value)
}

export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
    obj: X,
    prop: Y
): obj is X & Record<Y, unknown> {
    return Object.hasOwnProperty.call(obj, prop)
}
