import { ViewMode } from "./view-mode/view-mode"
import { ExpenseOverviewInfo, Type, Props } from "./types"
import { Button, Card, CardContent, Typography } from "@material-ui/core"
import { EditMode } from "./edit-mode/edit-mode"
import {
    initialState as initialEditModeState,
    State as EditModeState,
} from "./edit-mode/types"
import { Loading } from "../../../components/loading"
import { PaidChip } from "../../../components/paid-chip/chip"

export function ExpenseOverview(props: Props): JSX.Element {
    const { state, setState, editExpenseOverViewInfo } = props

    function setEditModeState(editModeState: EditModeState): void {
        if (state.mode.type === Type.View) throw new Error("Unreachable")
        setState({
            ...state,
            mode: {
                type: Type.Edit,
                editModeState,
            },
        })
    }

    function onSave(expenseOverviewInfo: ExpenseOverviewInfo): void {
        if (state.mode.type === Type.View) throw new Error("unreachable")
        setState({
            ...state,
            loading: true,
        })
        editExpenseOverViewInfo(expenseOverviewInfo).then(success => {
            if (success) {
                setState({
                    expenseOverviewInfo,
                    mode: {
                        type: Type.View,
                    },
                    loading: false,
                })
            } else {
                setState({
                    ...state,
                    mode: { type: Type.View },
                })
                alert("Er ging iets mis.")
            }
        })
    }

    function onCancel(): void {
        if (state.mode.type === Type.View) throw new Error("unreachable")
        setState({
            ...state,
            mode: {
                type: Type.View,
            },
        })
    }

    function onEditClick(): void {
        if (state.mode.type === Type.Edit) throw new Error("unreachable")
        setState({
            ...state,
            mode: {
                type: Type.Edit,
                editModeState: initialEditModeState(state.expenseOverviewInfo),
            },
        })
    }

    return (
        <Card>
            {state.loading ? (
                <Loading />
            ) : (
                <div>
                    <div className="flex flex-row justify-between m-4">
                        <div className="flex flex-row space-x-4 items-center">
                            <Typography variant="h5">Overzicht</Typography>
                            <PaidChip
                                paid={props.state.expenseOverviewInfo.isPaid}
                            />
                        </div>
                        {state.mode.type === Type.View && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onEditClick}
                            >
                                WIJZIG
                            </Button>
                        )}
                    </div>
                    <CardContent>
                        {state.mode.type === Type.View ? (
                            <ViewMode
                                expenseOverviewInfo={state.expenseOverviewInfo}
                            />
                        ) : (
                            <EditMode
                                state={state.mode.editModeState}
                                setState={setEditModeState}
                                onSave={onSave}
                                onCancel={onCancel}
                            />
                        )}
                    </CardContent>
                </div>
            )}
        </Card>
    )
}
