import { Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { Error as ErrorComponent } from "../../components/error/error"
import { Loading } from "../../components/loading"
import { Routes } from "../../constants"
import {
    AccountsQuery,
    BookingPeriod as RawBookingPeriod,
    ClientQuery,
    useAccountsQuery,
    useClientQuery,
    UserInfoQuery,
    useUserInfoQuery,
} from "../../generated/graphql"
import { logout } from "../../services/auth-service/auth.service"
import { BankAccountOverview } from "./bank-account-overview/bank-account-overview"
import { Account } from "./bank-account-overview/types"
import { CategoriesOverview } from "./categories/categories-overview"
import { Category } from "./categories/types"
import { ClientProfile } from "./client-profile/client-profile"
import { BookingPeriod, ClientInfo } from "./client-profile/types"
import { UserInfo } from "./user-profile/types"
import { UserProfile } from "./user-profile/user-profile"

function toBookingPeriod(rawBookingPeriod: RawBookingPeriod): BookingPeriod {
    switch (rawBookingPeriod) {
        case RawBookingPeriod.Monthly:
            return BookingPeriod.Monthly
        case RawBookingPeriod.Quarterly:
            return BookingPeriod.Quarterly
    }
}

function toClientInfo(clientQuery: ClientQuery): ClientInfo | null {
    const client = clientQuery.client
    if (client === null) return null
    return {
        name: client.name,
        kboNumber: client.kboNumber,
        bookingPeriod: toBookingPeriod(client.bookingPeriod),
        pontoClientId: client.pontoClientId ?? null,
        pontoSecret: client.pontoSecret ?? null,
        pontoCredentialsValid: client.pontoCredentialsValid ?? null,
    }
}

function toUserInfo(userQuery: UserInfoQuery): UserInfo | null {
    const user = userQuery.userInfo
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
    }
}

function toAccountInfo(accountsQuery: AccountsQuery): Account[] {
    return accountsQuery.accounts.map(account => ({
        id: account.id,
        balance: account.currentBalance,
        holderName: account.holderName,
        reference: account.reference,
        description: account.description,
    }))
}

function toCategories(clientQuery: ClientQuery): Category[] {
    const client = clientQuery.client
    if (client === null) return []
    return client.categories.map(category => ({
        id: category.id,
        code: category.code ?? "",
        description: category.description ?? "",
        name: category.name,
    }))
}

export function Profile(): JSX.Element {
    const history = useHistory()

    function onLogOut(): void {
        logout().then(() => history.push(Routes.Login))
    }

    const {
        loading: clientLoading,
        error: clientError,
        data: clientData,
    } = useClientQuery()
    const {
        loading: userLoading,
        error: userError,
        data: userData,
    } = useUserInfoQuery()
    const {
        loading: bankAccountsLoading,
        error: bankAccountError,
        data: bankAccountData,
    } = useAccountsQuery()
    if (clientLoading || userLoading || bankAccountsLoading) return <Loading />
    if (clientError || userError || bankAccountError)
        return (
            <ErrorComponent message="We kunnen uw profiel momenteel niet ophalen." />
        )
    if (
        clientData === undefined ||
        userData === undefined ||
        bankAccountData === undefined
    )
        return <ErrorComponent message="Er ging iets mis." />

    return (
        <div className="flex flex-row space-x-4 min-w-0 overflow-scroll pb-4">
            <div className="flex flex-row flex-grow space-x-4">
                <div className="flex flex-col space-y-4">
                    <ClientProfile clientInfo={toClientInfo(clientData)} />
                    <UserProfile userInfo={toUserInfo(userData)} />
                </div>
                <div className="flex flex-col flex-grow space-y-4">
                    <BankAccountOverview
                        accounts={toAccountInfo(bankAccountData)}
                    />
                    <CategoriesOverview categories={toCategories(clientData)} />
                </div>
            </div>
            <div className="flex flex-col">
                <Button color="primary" variant="contained" onClick={onLogOut}>
                    LOG UIT
                </Button>
            </div>
        </div>
    )
}
