import { ApolloClient, InMemoryCache, from } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { createUploadLink } from "apollo-upload-client"
import { onError } from "@apollo/client/link/error"
import { authHeader, logout } from "../services/auth-service/auth.service"
import { history } from "./browser-history"
import { Routes } from "../constants"

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_URL + "graphql",
})

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            ...authHeader(),
        },
    }
})

const authErrorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors !== undefined) {
        const hasAuthError = graphQLErrors.some(
            e =>
                e.extensions !== undefined &&
                e.extensions.code === "UNAUTHENTICATED"
        )
        if (hasAuthError) {
            logout().then(() => history.push(Routes.Login))
        }
    }
})

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authErrorLink, authLink, uploadLink]),
})
