import { Props } from "./types"

export function PaidChip(props: Props): JSX.Element {
    if (props.paid) {
        return (
            <div className="flex flex-row items-center space-x-1">
                <span
                    className="material-icons-outlined"
                    style={{ color: "green" }}
                >
                    done
                </span>
                <span style={{ color: "green" }}>Betaald</span>
            </div>
        )
    } else {
        return (
            <div className="flex flex-row items-center space-x-1">
                <span
                    className="material-icons-outlined"
                    style={{ color: "red" }}
                >
                    clear
                </span>
                <span style={{ color: "red" }}>Niet betaald</span>
            </div>
        )
    }
}
