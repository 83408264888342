import {
    FilterItem,
    GetInitialPages,
    initialState as initialTableState,
    PageBackwards,
    PageForwards,
    PagePayload,
    State as TableState,
} from "../../../components/table/types"

export const INITIAL_SORT_BY = "date"
export const INITIAL_PAGE_SIZE = 10

export enum SortBy {
    Id = "id",
    Recipient = "recipient",
    Communication = "communication",
    Date = "date",
    Amount = "amount",
}

export interface TransactionRow {
    readonly id: string
    readonly recipient: string | null
    readonly communication: string
    readonly date: Date | null
    readonly amount: number
}

export interface State {
    tableState: TableState<TransactionRow>
}

export interface Props {
    readonly state: State
    readonly setState: (state: State) => void
    readonly pageBackwards: PageBackwards<PagePayload<TransactionRow>>
    readonly pageForwards: PageForwards<PagePayload<TransactionRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<TransactionRow>>
    readonly availableFilterItems: FilterItem[]
}

export function initialState(
    rows: TransactionRow[],
    rowCount: number,
    startCursor: string,
    endCursor: string,
    selectedFilterItems: FilterItem[]
): State {
    return {
        tableState: initialTableState(
            rows,
            INITIAL_PAGE_SIZE,
            rowCount,
            INITIAL_SORT_BY,
            startCursor,
            endCursor,
            selectedFilterItems
        ),
    }
}
