import { Props } from "./types"

export function Detail(props: Props): JSX.Element {
    return (
        <div className="flex flex-col">
            <div>
                <b>{props.label}</b>
            </div>
            {props.value === null ? (
                <div className="text-secondary">Ongekend</div>
            ) : (
                <div className={props.currency ? "font-serif" : ""}>
                    {props.value}
                </div>
            )}
        </div>
    )
}
