import TextField from "@material-ui/core/TextField"
import React from "react"
import { InputBaseComponentProps } from "@material-ui/core"
import { NumberFormatCustomProps, Props } from "./types"
import NumberFormat from "react-number-format"

// Example: https://material-ui.com/components/text-fields/

function toNewValue(eventValue: string): number | null {
    return parseFloat(eventValue) || null
}

function CustomNumberFormat(props: NumberFormatCustomProps): JSX.Element {
    const { inputRef, onChange, ...other } = props
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values =>
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
        />
    )
}

export function AmountInput(props: Props): JSX.Element {
    return (
        <TextField
            variant="outlined"
            label={props.label}
            value={props.value}
            InputProps={{
                inputComponent:
                    CustomNumberFormat as unknown as React.ElementType<InputBaseComponentProps>,
            }}
            onChange={e => props.onChange(toNewValue(e.target.value))}
        />
    )
}
