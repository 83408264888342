import IconButton from "@material-ui/core/IconButton"
import { GridColDef } from "@mui/x-data-grid"
import {
    cellRenderer,
    currencyFormatter,
    dateFormatter,
    headerRenderer,
    Table,
} from "../../../components/table/table"
import {
    CellType,
    SortableColumn,
    State as TableState,
} from "../../../components/table/types"
import { routeToTransaction } from "../../../constants"
import { history } from "../../../providers/browser-history"
import { Props, TransactionRow } from "./types"

const colums: GridColDef[] = [
    {
        field: "id",
        renderCell: params => (
            <div className="m-auto">
                <IconButton
                    onClick={() => history.push(routeToTransaction(params.id))}
                >
                    <span className="material-icons-outlined">summarize</span>
                </IconButton>
            </div>
        ),
        renderHeader: () => <div />,
    },
    {
        field: "recipient",
        sortable: false,
        disableColumnMenu: true,
        headerName: "Ontvanger",
        flex: 1,
        renderHeader: headerRenderer,
        renderCell: params => cellRenderer(params, CellType.Normal),
    },
    {
        field: "communication",
        sortable: false,
        disableColumnMenu: true,
        headerName: "Communicatie",
        flex: 2.5,
        renderHeader: headerRenderer,
        renderCell: params => cellRenderer(params, CellType.Normal),
    },
    {
        field: "date",
        sortable: false,
        disableColumnMenu: true,
        headerName: "Datum",
        flex: 1,
        valueFormatter: dateFormatter,
        renderHeader: headerRenderer,
        renderCell: params => cellRenderer(params, CellType.Normal),
    },
    {
        field: "amount",
        sortable: false,
        disableColumnMenu: true,
        headerName: "Bedrag",
        flex: 1,
        valueFormatter: currencyFormatter,
        renderHeader: headerRenderer,
        renderCell: params => cellRenderer(params, CellType.Currency),
    },
]

const sortableColumns: SortableColumn[] = [
    {
        column: "recipient",
        label: "Ontvanger",
    },
    {
        column: "date",
        label: "Datum",
    },
    {
        column: "amount",
        label: "Bedrag",
    },
]

export function TransactionTable(props: Props): JSX.Element {
    const {
        state,
        setState,
        pageBackwards,
        pageForwards,
        getInitialPages,
        availableFilterItems,
    } = props

    function setTableState(tableState: TableState<TransactionRow>): void {
        setState({
            tableState,
        })
    }

    return (
        <Table
            title="Transacties"
            state={state.tableState}
            setState={setTableState}
            columns={colums}
            sortableColumns={sortableColumns}
            pageBackwards={pageBackwards}
            pageForwards={pageForwards}
            getInitialPages={getInitialPages}
            availableFilterItems={availableFilterItems}
        />
    )
}
