import { useState } from "react"
import { Switch, Redirect } from "react-router-dom"
import { Bank } from "../../views/bank/bank"
import { Expenses } from "../../views/expenses/expenses"
import { Routes } from "../../constants"
import { initialState, State } from "./types"
import { NavRail } from "../nav-rail/nav-rail"
import { ProtectedRoute } from "../../components/protected-route"
import { Expense } from "../../views/expense/expense"
import { Profile } from "../../views/profile/profile"
import { Transaction } from "../../views/transaction/transaction"

export function Container(): JSX.Element {
    const [state, setState] = useState<State>(initialState())
    return (
        <div className="flex flex-row w-screen h-screen p-4 space-x-4">
            <aside className="h-full">
                <NavRail
                    selectedView={state.view}
                    setSelectedView={view => setState({ view })}
                />
            </aside>
            <main className="flex-grow">
                <Switch>
                    <ProtectedRoute
                        exact
                        path={Routes.Expenses}
                        component={Expenses}
                    />
                    <ProtectedRoute
                        exact
                        path={Routes.Expense}
                        component={Expense}
                    />
                    <ProtectedRoute exact path={Routes.Bank} component={Bank} />
                    <ProtectedRoute
                        exact
                        path={Routes.Transaction}
                        component={Transaction}
                    />
                    <ProtectedRoute
                        exact
                        path={Routes.Profile}
                        component={Profile}
                    />
                    <ProtectedRoute
                        render={() => <Redirect to={Routes.Expenses} />}
                    />
                </Switch>
            </main>
        </div>
    )
}
