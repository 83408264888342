import { useDrag } from "react-dnd"
import { PageDraggableType } from "../../types"
import { Props } from "./types"

export function PagePreview(props: Props): JSX.Element {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: PageDraggableType,
        item: props.page,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }))

    return (
        <div ref={drag} className={isDragging ? "opacity-0" : ""}>
            <img src={props.page.url} alt="" />
        </div>
    )
}
