import {
    Category,
    CategoryAssignment,
    initialState as initialExpenseCategoriesState,
    State as ExpenseCategoriesState,
} from "../expense-categories/types"
import {
    ExpenseOverviewInfo,
    initialState as initialExpenseOverviewState,
    State as ExpenseOverViewState,
} from "../expense-overview/types"

export interface State {
    readonly expenseOverViewState: ExpenseOverViewState
    readonly expenseCategoriesState: ExpenseCategoriesState
}

export interface Data {
    readonly expenseOverviewInfo: ExpenseOverviewInfo
    readonly expenseCategories: CategoryAssignment[]
    readonly pdfUrl: string
}

export interface Mutations {
    readonly editExpenseOverviewInfo: (
        newExpenseOverViewInfo: ExpenseOverviewInfo
    ) => Promise<boolean>
    readonly editExpenseCategories: (
        newExpenseCategories: CategoryAssignment[]
    ) => Promise<boolean>
}

export interface Queries {
    readonly getCategories: () => Promise<Category[] | null>
}

export interface Props {
    readonly data: Data
    readonly mutations: Mutations
    readonly queries: Queries
}

export function getInitialState(data: Data): State {
    return {
        expenseOverViewState: initialExpenseOverviewState(
            data.expenseOverviewInfo
        ),
        expenseCategoriesState: initialExpenseCategoriesState(
            data.expenseCategories
        ),
    }
}
