import { ExpenseOverviewInfo } from "../types"

export interface State {
    readonly expenseOverviewInfo: ExpenseOverviewInfo
}

export interface Props {
    readonly state: State
    readonly setState: (newState: State) => void
    readonly onSave: (expenseOverviewInfo: ExpenseOverviewInfo) => void
    readonly onCancel: () => void
}

export function initialState(expenseOverviewInfo: ExpenseOverviewInfo): State {
    return {
        expenseOverviewInfo,
    }
}
