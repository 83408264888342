import { Card, CardContent, makeStyles, Typography } from "@material-ui/core"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import React from "react"
import { RenderCellExpand } from "../../../components/render-cell-expand"
import { CreateNewCategory } from "./create-new-category"
import { DeleteCategory } from "./delete-category/delete-category"
import { EditCategory } from "./edit-category/edit-category"
import { Props } from "./types"

export function CategoriesOverview(props: Props): JSX.Element {
    const useStyles = makeStyles({
        root: {
            border: 0,
        },
    })

    const columns: GridColDef[] = [
        {
            field: "code",
            headerName: "Code",
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: "Naam",
            flex: 2,
            disableColumnMenu: true,
        },
        {
            field: "description",
            headerName: "Beschrijving",
            flex: 3,
            disableColumnMenu: true,
            sortable: false,
            renderCell: params => RenderCellExpand(params),
        },
        {
            field: "id",
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: params => (
                <div className="flex flex-row justify-center items-center">
                    <EditCategory
                        category={{
                            id: params.row.id,
                            name: params.row.name,
                            code: params.row.code,
                            description: params.row.description,
                        }}
                    />
                    <DeleteCategory
                        category={{
                            id: params.row.id,
                            name: params.row.name,
                            code: params.row.code,
                            description: params.row.description,
                        }}
                    />
                </div>
            ),
            renderHeader: () => (
                <div className="flex flex-row justify-end"></div>
            ),
        },
    ]

    return (
        <Card>
            <div className="flex flex-row justify-between m-4">
                <Typography variant="h5">Categorieën</Typography>
                <CreateNewCategory />
            </div>
            <CardContent>
                <div className="flex flex-col space-y-4 flex-1">
                    <DataGrid
                        classes={useStyles()}
                        rows={props.categories}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoHeight
                    />
                </div>
            </CardContent>
        </Card>
    )
}
