import {
    GetInitialPages,
    initialState as initialTableState,
    PageBackwards,
    PageForwards,
    PagePayload,
    State as TableState,
} from "../../../components/table/types"
import { BookingPeriod, ExpenseType } from "../../../generated/graphql"

export const INITIAL_SORT_BY = "id"
export const INITIAL_PAGE_SIZE = 10

export interface ExpenseRow {
    readonly id: string
    readonly isPaid: boolean
    readonly bookingPeriod: number | null
    readonly type: ExpenseType | null
    readonly date: Date | null
    readonly supplier: string | null
    readonly grossAmount: number | null
    readonly netAmount: number | null
    readonly reference: string | null
    readonly docString: string | null
}

export interface State {
    tableState: TableState<ExpenseRow>
}

export interface Props {
    readonly state: State
    readonly setState: (state: State) => void
    readonly bookingPeriod: BookingPeriod
    readonly pageBackwards: PageBackwards<PagePayload<ExpenseRow>>
    readonly pageForwards: PageForwards<PagePayload<ExpenseRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<ExpenseRow>>
}

export function initialState(
    rows: ExpenseRow[],
    rowCount: number,
    startCursor: string,
    endCursor: string
): State {
    return {
        tableState: initialTableState(
            rows,
            INITIAL_PAGE_SIZE,
            rowCount,
            INITIAL_SORT_BY,
            startCursor,
            endCursor,
            undefined
        ),
    }
}
