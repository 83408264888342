export interface CancelablePromise<T> {
    promise: Promise<T>
    cancel: () => void
}

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
    let canceled = false

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise
            .then(val => {
                if (!canceled) resolve(val)
            })
            .catch(error => {
                if (!canceled) reject(error)
            })
    })

    return {
        promise: wrappedPromise,
        cancel() {
            canceled = true
        },
    }
}
