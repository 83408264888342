import {
    useCreateExpensesMutation,
    useUploadFilesMutation,
} from "../../generated/graphql"
import { PendingExpense } from "./group-pages/pending-expenses-list/pending-expense/types"
import { Upload } from "./group-pages/uploads-overview/types"
import { UploadFilesContainer } from "./upload-files-container/upload-files-container"

export function UploadFiles(): JSX.Element {
    const [uploadFilesMutation] = useUploadFilesMutation()
    const [createExpensesMutation] = useCreateExpensesMutation({
        refetchQueries: ["Expenses"],
    })

    function uploadFiles(files: File[]): Promise<Upload[] | null> {
        return uploadFilesMutation({ variables: { files } }).then(result => {
            if (result.data) {
                return result.data.createUploads.map(u => ({
                    id: u.id,
                    name: u.originalName,
                    pages: u.pages.map(p => ({
                        ...p,
                        fileName: u.originalName,
                    })),
                }))
            } else {
                return null
            }
        })
    }

    function createExpenses(
        pendingExpenses: PendingExpense[]
    ): Promise<boolean> {
        return createExpensesMutation({
            variables: {
                input: pendingExpenses.map(pe => ({
                    pageIds: pe.pages.map(p => p.id),
                })),
            },
        })
            .then(result => (result.data ? true : false))
            .catch(() => false)
    }

    return (
        <UploadFilesContainer
            uploadFiles={uploadFiles}
            createExpenses={createExpenses}
        />
    )
}
