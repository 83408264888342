import { TransactionOverview } from "../transaction-overview/types"
import {
    LinkedExpenseRow,
    State as SelectLinkedExpensesTableState,
} from "../add-linked-expenses-table/types"
import { initialState as initialSelectLinkedExpensesTableState } from "../add-linked-expenses-table/types"
import {
    GetInitialPages,
    PageBackwards,
    PageForwards,
    PagePayload,
} from "../../../components/table/types"

export interface State {
    addLinkedexpensesTableState: SelectLinkedExpensesTableState
    readonly selectedExpenseIds: string[]
    readonly shownExpenseUrl: string | null
}

export interface Props {
    readonly state: State
    readonly setState: (state: State) => void
    readonly open: boolean
    readonly previouslyLinkedExpenseIds: string[]
    readonly onCancel: () => void
    readonly onSave: (ids: string[]) => void
    readonly transactionOverview: TransactionOverview
    readonly pageBackwards: PageBackwards<PagePayload<LinkedExpenseRow>>
    readonly pageForwards: PageForwards<PagePayload<LinkedExpenseRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<LinkedExpenseRow>>
}

export function initialState(): State {
    return {
        addLinkedexpensesTableState: initialSelectLinkedExpensesTableState(),
        selectedExpenseIds: [],
        shownExpenseUrl: null,
    }
}
