import { useParams } from "react-router-dom"
import { toExpenseOrderBy, toPagePayload, UrlParams } from "./types"
import { TransactionContainer } from "./transaction-container/transaction-container"
import {
    useAddLinkedExpensesMutation,
    useDeleteLinkedExpensesMutation,
    useExpenseSummariesQuery,
    useTransactionQuery,
} from "../../generated/graphql"
import { Loading } from "../../components/loading"
import { Error as ErrorComponent } from "../../components/error/error"
import { toTransactionOverview } from "./types"
import React from "react"
import {
    PagePayload,
    SortDirection,
    toSortOrder,
} from "../../components/table/types"
import { LinkedExpenseRow } from "./add-linked-expenses-table/types"
import { isoStringToDate } from "../../utils/date-util"
import { ExpenseSummary } from "./linked-expenses/types"

const baseVariables = {
    last: null,
    before: null,
    first: null,
    after: null,
    orderBy: null,
    direction: null,
    query: null,
}

export function Transaction(): JSX.Element {
    const { transactionId } = useParams<UrlParams>()
    const { refetch: fetchExpenseSummaries } = useExpenseSummariesQuery({
        skip: true,
    })

    function pageBackwards(
        last: number,
        before: string,
        sortBy: string,
        sortDirection: SortDirection,
        query: string
    ): Promise<PagePayload<LinkedExpenseRow> | null> {
        return fetchExpenseSummaries({
            ...baseVariables,
            last,
            before,
            orderBy: toExpenseOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
        })
            .then(result => toPagePayload(result.data))
            .catch(null)
    }

    function pageForwards(
        first: number,
        after: string,
        sortBy: string,
        sortDirection: SortDirection,
        query: string
    ): Promise<PagePayload<LinkedExpenseRow> | null> {
        return fetchExpenseSummaries({
            ...baseVariables,
            first,
            after,
            orderBy: toExpenseOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
        })
            .then(result => toPagePayload(result.data))
            .catch(null)
    }

    function getInitialPages(
        pageSize: number,
        sortBy: string,
        sortDirection: SortDirection,
        query: string
    ): Promise<PagePayload<LinkedExpenseRow> | null> {
        return fetchExpenseSummaries({
            ...baseVariables,
            first: pageSize,
            orderBy: toExpenseOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
        })
            .then(result => {
                return toPagePayload(result.data)
            })
            .catch(null)
    }

    const [addLinkedExpensesMutation] = useAddLinkedExpensesMutation()

    function addLinkedExpenses(
        ids: string[]
    ): Promise<{ success: boolean; expenses: ExpenseSummary[] }> {
        return addLinkedExpensesMutation({
            variables: {
                transactionId,
                addedExpenseIds: ids,
            },
        })
            .then(res => {
                if (
                    res.data === undefined ||
                    res.data === null ||
                    res.data.editTransaction === undefined
                ) {
                    return {
                        success: false,
                        expenses: [],
                    }
                } else {
                    const expenses: ExpenseSummary[] =
                        res.data.editTransaction.expenses.map(e => ({
                            ...e,
                            date: isoStringToDate(e.date),
                        }))
                    return {
                        success: true,
                        expenses,
                    }
                }
            })
            .catch(res => ({
                success: false,
                expenses: [],
            }))
    }

    const [deleteLinkedExpensesMutation] = useDeleteLinkedExpensesMutation()

    function deleteLinkedExpenses(ids: string[]): Promise<boolean> {
        return deleteLinkedExpensesMutation({
            variables: {
                transactionId,
                deletedExpenseIds: ids,
            },
        })
            .then(() => true)
            .catch(() => false)
    }

    const { loading, data, error } = useTransactionQuery({
        variables: {
            id: transactionId,
        },
    })

    if (loading) return <Loading />
    if (error)
        return (
            <ErrorComponent message="We vinden de details van deze transactie niet terug." />
        )
    if (data === undefined)
        return <ErrorComponent message="Er ging iets mis." />

    return (
        <TransactionContainer
            data={{
                transactionOverview: toTransactionOverview(data),
                linkedExpenses: data.transaction.expenses.map(e => ({
                    ...e,
                    date: isoStringToDate(e.date),
                })),
            }}
            queries={{
                pageBackwards,
                pageForwards,
                getInitialPages,
            }}
            mutations={{
                deleteLinkedExpenses,
                addLinkedExpenses,
            }}
        />
    )
}
