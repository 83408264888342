import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@material-ui/core"
import React from "react"
import { Loading } from "../../../../components/loading"
import { useEditClientMutation } from "../../../../generated/graphql"
import { Props } from "./types"

export function UpdatePontoCredentialsDialog(props: Props): JSX.Element {
    const [pontoClientId, setPontoClientId] = React.useState(
        props.pontoClientId
    )
    const [pontoSecret, setPontoSecret] = React.useState("")
    const [loading, setLoading] = React.useState(false)

    const [editMutation] = useEditClientMutation({
        refetchQueries: ["client"],
    })

    function onCancel(): void {
        setPontoClientId(props.pontoClientId)
        setPontoSecret("")
        props.onClose()
    }

    function onSave(): void {
        setLoading(true)
        editMutation({
            variables: {
                data: {
                    pontoClientId,
                    pontoSecret,
                },
            },
        }).then(result => {
            setLoading(false)
            if (!result.data) alert("Er ging iets mis.")
            props.onClose()
        })
    }

    return (
        <div>
            <Dialog open={props.open} onClose={onCancel}>
                <DialogTitle>Wijzig MyPonto credentials</DialogTitle>
                <DialogContent>
                    <div className="m-4 w-64">
                        {!loading ? (
                            <div className="flex flex-col space-y-4">
                                <TextField
                                    label="MyPonto CLIENT ID"
                                    variant="outlined"
                                    color="primary"
                                    onChange={e => {
                                        setPontoClientId(e.target.value)
                                    }}
                                    value={pontoClientId}
                                />
                                <TextField
                                    label="MyPonto CLIENT SECRET"
                                    variant="outlined"
                                    color="primary"
                                    onChange={e => {
                                        setPontoSecret(e.target.value)
                                    }}
                                    value={pontoSecret}
                                />
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onCancel()} disabled={loading}>
                        ANNULEER
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onSave()}
                        disabled={
                            loading ||
                            pontoClientId.length === 0 ||
                            pontoSecret.length === 0
                        }
                    >
                        OPSLAAN
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
