import { Divider } from "@material-ui/core"
import { PendingExpensesList } from "./pending-expenses-list/pending-expenses-list"
import { Props } from "./types"
import { UploadsOverview } from "./uploads-overview/uploads-overview"

export function GroupPages(props: Props): JSX.Element {
    const { state, dispatch } = props
    return (
        <div className="h-full w-full flex flex-row space-x-4">
            <div className="w-1/2 flex flex-col h-full space-y-4">
                <div className="font-bold">Pagina's</div>
                <UploadsOverview dispatch={dispatch} uploads={state.uploads} />
            </div>
            <Divider orientation="vertical" flexItem variant="fullWidth" />
            <div className="w-1/2 flex flex-col h-full space-y-4">
                <div className="font-bold">Uitgaven</div>
                <PendingExpensesList
                    state={state.pendingExpensesState}
                    dispatch={dispatch}
                />
            </div>
        </div>
    )
}
