import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core"
import { Props } from "./types"
import { formatCurrencyInEuro } from "../../../../utils/currency-util"

export function ViewMode(props: Props): JSX.Element {
    const categoryAssignments = props.categoryAssignments
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <b>Categorie</b>
                    </TableCell>
                    <TableCell>
                        <b>Bedrag</b>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {categoryAssignments.map(categoryAssignment => (
                    <TableRow key={categoryAssignment.category.id}>
                        <TableCell>
                            {categoryAssignment.category.name}
                        </TableCell>
                        <TableCell>
                            <div className="font-serif">
                                {formatCurrencyInEuro(
                                    categoryAssignment.amount
                                )}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
