import { Divider, MenuItem } from "@material-ui/core"
import { Props } from "./types"
import TextField from "@material-ui/core/TextField"
import { DatePicker } from "../../../../components/date-picker/date-picker"
import { ExpenseType, VatRegime } from "../../../../generated/graphql"
import { CurrencyInput } from "../../../../components/currency-input/currency-input"
import { AmountInput } from "../../../../components/amount-input/amount-input"
import { vatRegimeToString } from "../../../../utils/vat-regime-util"
import Button from "@material-ui/core/Button"
import { isValid } from "date-fns"

const currencies = ["EUR", "USD", "GBP", "SEK", "CHF", "CNY"].sort()
const vatRegimes = Object.values(VatRegime)

export function EditMode(props: Props): JSX.Element {
    const { state, setState, onSave, onCancel } = props
    const expenseOverviewInfo = state.expenseOverviewInfo
    const showForeignCurrency = expenseOverviewInfo.currency !== "EUR"

    function onCurrencyChange(currency: string): void {
        if (currency === "EUR") {
            setState({
                ...state,
                expenseOverviewInfo: {
                    ...expenseOverviewInfo,
                    currency,
                    exchangeRate: null,
                    totalForeignCurrency: null,
                },
            })
        } else {
            setState({
                ...state,
                expenseOverviewInfo: {
                    ...expenseOverviewInfo,
                    currency,
                },
            })
        }
    }

    function saveDisabled(): boolean {
        const expenseDetails = state.expenseOverviewInfo
        const dates: (Date | null)[] = [
            expenseDetails.date,
            expenseDetails.expirationDate,
            expenseDetails.periodEnd,
            expenseDetails.periodStart,
        ]
        return dates.some(date => date !== null && !isValid(date))
    }

    return (
        <div>
            <div className="grid grid-cols-2 gap-6">
                <TextField
                    select
                    variant="outlined"
                    value={expenseOverviewInfo.type}
                    label="Type"
                    onChange={event =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                type: event.target.value as ExpenseType,
                            },
                        })
                    }
                >
                    <MenuItem value={ExpenseType.Af}>Factuur</MenuItem>
                    <MenuItem value={ExpenseType.Cn}>Creditnota</MenuItem>
                </TextField>
                <TextField
                    label="Leverancier"
                    variant="outlined"
                    value={expenseOverviewInfo.supplier}
                    onChange={e =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                supplier: e.target.value,
                            },
                        })
                    }
                />
                <DatePicker
                    label="Datum"
                    value={expenseOverviewInfo.date}
                    onChange={date =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                date,
                            },
                        })
                    }
                />
                <DatePicker
                    label="Vervaldatum"
                    value={expenseOverviewInfo.expirationDate}
                    onChange={expirationDate =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                expirationDate,
                            },
                        })
                    }
                />
                <TextField
                    label="Referentie"
                    variant="outlined"
                    value={expenseOverviewInfo.reference}
                    onChange={e =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                reference: e.target.value,
                            },
                        })
                    }
                />
                <TextField
                    label="Omschrijving"
                    variant="outlined"
                    value={expenseOverviewInfo.description}
                    onChange={e =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                description: e.target.value,
                            },
                        })
                    }
                />
                <div className="col-span-2">Periode:</div>
                <DatePicker
                    label="Begin"
                    value={expenseOverviewInfo.periodStart}
                    onChange={periodStart =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                periodStart,
                            },
                        })
                    }
                />
                <DatePicker
                    label="Einde"
                    value={expenseOverviewInfo.periodEnd}
                    onChange={periodEnd =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                periodEnd,
                            },
                        })
                    }
                />
                <Divider className="col-span-2" light />
                <div className="col-span-2">Bruto:</div>
                <TextField
                    select
                    variant="outlined"
                    value={expenseOverviewInfo.currency}
                    label="Munteenheid"
                    onChange={e => onCurrencyChange(e.target.value)}
                >
                    {currencies.map(currency => (
                        <MenuItem value={currency}>{currency}</MenuItem>
                    ))}
                </TextField>
                {showForeignCurrency && (
                    <AmountInput
                        label={"Totaal vreemd valuta"}
                        value={expenseOverviewInfo.totalForeignCurrency}
                        onChange={totalForeignCurrency =>
                            setState({
                                ...state,
                                expenseOverviewInfo: {
                                    ...expenseOverviewInfo,
                                    totalForeignCurrency,
                                },
                            })
                        }
                    />
                )}
                {showForeignCurrency && (
                    <AmountInput
                        label={"Koers"}
                        value={expenseOverviewInfo.exchangeRate}
                        onChange={exchangeRate =>
                            setState({
                                ...state,
                                expenseOverviewInfo: {
                                    ...expenseOverviewInfo,
                                    exchangeRate,
                                },
                            })
                        }
                    />
                )}
                <CurrencyInput
                    label={"Bruto"}
                    value={expenseOverviewInfo.grossAmount}
                    onChange={grossAmount =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                grossAmount,
                            },
                        })
                    }
                />
                <Divider className="col-span-2" light />
                <CurrencyInput
                    label={"Netto"}
                    value={expenseOverviewInfo.netAmount}
                    onChange={netAmount =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                netAmount,
                            },
                        })
                    }
                />
                <CurrencyInput
                    label={"Aftrekbare btw"}
                    value={expenseOverviewInfo.deductibleVat}
                    onChange={deductibleVat =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                deductibleVat,
                            },
                        })
                    }
                />
                <div className="col-span-2">Niet-aftrekbare BTW:</div>
                <CurrencyInput
                    label={"Bedrijf"}
                    value={expenseOverviewInfo.nonDeductibleCorporateVat}
                    onChange={nonDeductibleCorporateVat =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                nonDeductibleCorporateVat,
                            },
                        })
                    }
                />
                <CurrencyInput
                    label={"Privé"}
                    value={expenseOverviewInfo.nonDeductiblePrivateVat}
                    onChange={nonDeductiblePrivateVat =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                nonDeductiblePrivateVat,
                            },
                        })
                    }
                />
                <Divider className="col-span-2" light />
                <CurrencyInput
                    label={"Disconto"}
                    value={expenseOverviewInfo.discount}
                    onChange={discount =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                discount,
                            },
                        })
                    }
                />
                <CurrencyInput
                    label={"Leeggoed"}
                    value={expenseOverviewInfo.deposit}
                    onChange={deposit =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                deposit,
                            },
                        })
                    }
                />
                <TextField
                    select
                    variant="outlined"
                    value={expenseOverviewInfo.vatRegime}
                    label="BTW regime"
                    onChange={event =>
                        setState({
                            ...state,
                            expenseOverviewInfo: {
                                ...expenseOverviewInfo,
                                vatRegime: event.target.value as VatRegime,
                            },
                        })
                    }
                >
                    {vatRegimes.map(vatRegime => (
                        <MenuItem value={vatRegime}>
                            {vatRegimeToString(vatRegime)}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className="flex flex-row space-x-4 justify-end">
                <Button onClick={onCancel}>ANNULEER</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSave(state.expenseOverviewInfo)}
                    disabled={saveDisabled()}
                >
                    OPSLAAN
                </Button>
            </div>
        </div>
    )
}
