import { KeyboardDatePicker } from "@material-ui/pickers"
import { Props } from "./types"

export function DatePicker(props: Props): JSX.Element {
    return (
        <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            format="dd/MM/yyyy"
            invalidDateMessage="Ongeldige datum"
            label={props.label}
            inputVariant="outlined"
            value={props.value}
            onChange={props.onChange}
            PopoverProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            }}
        />
    )
}
