import AddIcon from "@material-ui/icons/Add"
import { useDrop } from "react-dnd"
import { hasOwnProperty } from "../../../../../utils/util"
import { PageDraggableType } from "../../types"
import { Page } from "../../uploads-overview/page-preview/types"
import { PendingExpense as PendingExpenseType, Props } from "./types"
import { useState } from "react"
import Button from "@material-ui/core/Button"

function getLabel(pendingExpense: PendingExpenseType): string {
    const fileName = pendingExpense.pages[0].fileName
    return pendingExpense.pages.every(p => p.fileName === fileName)
        ? fileName
        : "Meerdere bestanden"
}

export function PendingExpense(props: Props): JSX.Element {
    const [isHovered, setHovered] = useState(false)
    const [, drop] = useDrop(() => ({
        accept: PageDraggableType,
        drop: item => {
            if (!validatePage(item)) throw new Error("page validation failed")
            props.onDrop(item as Page) // validation makes sure cast is valid
        },
    }))

    function validatePage(item: unknown): boolean {
        return (
            typeof item === "object" &&
            item !== null &&
            item !== undefined &&
            hasOwnProperty(item, "url") &&
            hasOwnProperty(item, "id") &&
            typeof item.url === "string" &&
            typeof item.id === "string"
        )
    }

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            ref={drop}
            className={`relative border ${
                props.pendingExpense !== undefined ? "" : "border-dotted"
            } rounded-lg p-4 h-full`}
        >
            {props.pendingExpense !== undefined ? (
                isHovered ? (
                    <div className="w-full h-full flex flex-col space-y-4 items-center justify-center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.onCancel}
                        >
                            ZET TERUG
                        </Button>
                        <div>{getLabel(props.pendingExpense)}</div>
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-2 overflow-hidden max-h-full">
                        {props.pendingExpense.pages.map(p => (
                            <div key={p.id} className="flex-1">
                                <img src={p.url} alt="" />
                            </div>
                        ))}
                    </div>
                )
            ) : (
                <div className="flex justify-center items-center h-full">
                    <AddIcon />
                </div>
            )}
        </div>
    )
}
