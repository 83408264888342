import { TransactionOverview } from "../transaction-overview/types"
import { ExpenseSummary } from "../linked-expenses/types"
import {
    State as LinkedExpensesState,
    initialState as initialLinkedExpensesState,
} from "../linked-expenses/types"
import {
    GetInitialPages,
    PageBackwards,
    PageForwards,
    PagePayload,
} from "../../../components/table/types"
import { LinkedExpenseRow } from "../add-linked-expenses-table/types"

export interface Data {
    readonly transactionOverview: TransactionOverview
    readonly linkedExpenses: readonly ExpenseSummary[]
}

export interface Mutations {
    readonly deleteLinkedExpenses: (id: string[]) => Promise<boolean>
    readonly addLinkedExpenses: (
        ids: string[]
    ) => Promise<{ success: boolean; expenses: ExpenseSummary[] }>
}

export interface Queries {
    readonly pageBackwards: PageBackwards<PagePayload<LinkedExpenseRow>>
    readonly pageForwards: PageForwards<PagePayload<LinkedExpenseRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<LinkedExpenseRow>>
}

export interface State {
    readonly linkedExpensesState: LinkedExpensesState
}

export interface Props {
    readonly data: Data
    readonly queries: Queries
    readonly mutations: Mutations
}

export function initialState(linkedExpenses: readonly ExpenseSummary[]): State {
    return {
        linkedExpensesState: initialLinkedExpensesState(linkedExpenses),
    }
}
