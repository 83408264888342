import { TransactionOverview } from "../transaction-overview/transaction-overview"
import { initialState, Props } from "./types"
import { LinkedExpenses } from "../linked-expenses/linked-expenses"
import { useState } from "react"
import { State } from "./types"
import { ExpensePDF } from "../../expense/expense-pdf/expense-pdf"

export function TransactionContainer(props: Props): JSX.Element {
    const { data, queries, mutations } = props
    const [state, setState] = useState<State>(initialState(data.linkedExpenses))

    return (
        <div className="flex flex-row w-full h-full min-h-0">
            <div className="w-5/12 h-full overflow-scroll">
                <div className="flex flex-col space-y-4">
                    <TransactionOverview
                        transactionOverview={data.transactionOverview}
                    />
                    <LinkedExpenses
                        state={state.linkedExpensesState}
                        setState={s =>
                            setState({ ...state, linkedExpensesState: s })
                        }
                        transactionOverview={data.transactionOverview}
                        pageForwards={queries.pageForwards}
                        pageBackwards={queries.pageBackwards}
                        getInitialPages={queries.getInitialPages}
                        deleteLinkedExpense={mutations.deleteLinkedExpenses}
                        addLinkedExpenses={mutations.addLinkedExpenses}
                    />
                </div>
            </div>
            <div className="w-7/12 h-full">
                {state.linkedExpensesState.selectedExpense !== null && (
                    <ExpensePDF
                        url={state.linkedExpensesState.selectedExpense.url}
                    />
                )}
            </div>
        </div>
    )
}
