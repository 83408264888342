import { TransactionOverview } from "./transaction-overview/types"
import {
    ExpensesOrderBy,
    ExpenseSummariesQuery,
    TransactionQuery,
} from "../../generated/graphql"
import { PagePayload } from "../../components/table/types"
import { LinkedExpenseRow } from "./add-linked-expenses-table/types"
import { isoStringToDate } from "../../utils/date-util"

export interface UrlParams {
    readonly transactionId: string
}

export function toTransactionOverview(
    transactionQuery: TransactionQuery
): TransactionOverview {
    const transaction = transactionQuery.transaction
    return {
        amount: transaction.amount,
        counterpartName: transaction.counterpartName,
        counterpartReference: transaction.counterpartReference,
        remittanceInformation: transaction.remittanceInformation,
        executionDate: new Date(transaction.executionDate),
        valueDate: new Date(transaction.valueDate),
        description: transaction.description,
        currency: transaction.currency,
        additionalInformation: transaction.additionalInformation,
    }
}

export function toRows(
    expenseSummariesQuery: ExpenseSummariesQuery
): LinkedExpenseRow[] {
    return expenseSummariesQuery.expenses.edges.map(edge => ({
        id: edge.node.id,
        date: isoStringToDate(edge.node.date),
        supplier: edge.node.supplier,
        grossAmount: edge.node.grossAmount,
        selected: false,
        url: edge.node.url,
    }))
}

export function toExpenseOrderBy(column: string): ExpensesOrderBy {
    switch (column) {
        case "id":
            return ExpensesOrderBy.Id
        case "date":
            return ExpensesOrderBy.Date
        case "grossAmount":
            return ExpensesOrderBy.GrossAmount
        case "supplier":
            return ExpensesOrderBy.Supplier
        default:
            throw new Error("Received an unexpected sortable column: " + column)
    }
}

export function toPagePayload(
    expenseSummariesQuery: ExpenseSummariesQuery
): PagePayload<LinkedExpenseRow> | null {
    const edgeCount = expenseSummariesQuery.expenses.pageInfo.edgeCount
    return edgeCount === null
        ? null
        : {
              rows: toRows(expenseSummariesQuery),
              startCursor: expenseSummariesQuery.expenses.pageInfo.startCursor,
              endCursor: expenseSummariesQuery.expenses.pageInfo.endCursor,
              rowCount: edgeCount,
          }
}
