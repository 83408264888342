import { State as EditModeState } from "./edit-mode/types"

export interface Category {
    readonly id: string
    readonly name: string
    readonly code: string
}

export interface CategoryAssignment {
    readonly category: Category
    readonly amount: number
}

export enum Type {
    View = "view",
    Edit = "edit",
}

export interface ViewMode {
    readonly type: Type.View
}

export interface EditMode {
    readonly type: Type.Edit
    readonly editModeState: EditModeState
}

export type State = {
    readonly categoryAssignments: CategoryAssignment[]
    readonly mode: ViewMode | EditMode
    readonly loading: boolean
}

export interface Props {
    readonly state: State
    readonly setState: (state: State) => void
    readonly editCategoryAssignments: (
        newCategoryAssignments: CategoryAssignment[]
    ) => Promise<boolean>
    readonly getCategories: () => Promise<Category[] | null>
}

export function initialState(categoryAssignments: CategoryAssignment[]): State {
    return {
        categoryAssignments,
        mode: { type: Type.View },
        loading: false,
    }
}
