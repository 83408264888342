import { useState } from "react"
import { FilterItem } from "../../../components/table/types"
import { AccountsBalance } from "../accounts-balance/accounts-balance"
import { TransactionTable } from "../transaction-table/transaction-table"
import { Account, initialState, Props, State } from "./types"

export function BankContainer(props: Props): JSX.Element {
    const { tableData, accounts } = props.data

    const [state, setState] = useState<State>(
        initialState(
            tableData.rows,
            tableData.rowCount,
            tableData.startCursor,
            tableData.endCursor,
            toFilterItems(accounts)
        )
    )

    function toFilterItems(accounts: Account[]): FilterItem[] {
        return accounts.map(account => ({
            id: account.id,
            label: account.reference,
        }))
    }

    function totalAccountBalance(accounts: Account[]): number | null {
        const activeFilterItems =
            state.transactionTableState.tableState.selectedFilterItems
        if (!activeFilterItems) return null
        return accounts
            .filter(account =>
                activeFilterItems.map(item => item.id).includes(account.id)
            )
            .reduce((total, account) => total + account.balance, 0)
    }

    return (
        <div className="flex flex-col space-y-4">
            <div className="self-start">
                <AccountsBalance totalBalance={totalAccountBalance(accounts)} />
            </div>
            <TransactionTable
                state={state.transactionTableState}
                setState={newState =>
                    setState({ transactionTableState: newState })
                }
                pageForwards={props.queries.pageForwards}
                pageBackwards={props.queries.pageBackwards}
                getInitialPages={props.queries.getInitialPages}
                availableFilterItems={toFilterItems(accounts)}
            />
        </div>
    )
}
