import { Error } from "../../components/error/error"
import { Loading } from "../../components/loading"
import {
    FilterItem,
    INITIAL_SORT_DIRECTION,
    PagePayload,
    SortDirection,
    toSortOrder,
} from "../../components/table/types"
import {
    AccountQuery,
    useAccountQuery,
    useTransactionsQuery,
} from "../../generated/graphql"
import { BankContainer } from "./bank-container/bank-container"
import { Account } from "./bank-container/types"
import { INITIAL_SORT_BY, TransactionRow } from "./transaction-table/types"
import { toPagePayload, toRows, toTransactionOrderBy } from "./types"

export const INITIAL_PAGE_SIZE = 10

const baseVariables = {
    last: null,
    before: null,
    first: null,
    after: null,
    orderBy: null,
    direction: null,
    query: "",
    accountIds: null,
}

export function Bank(): JSX.Element {
    const { refetch: fetchTransactions } = useTransactionsQuery({ skip: true })

    function pageBackwards(
        last: number,
        before: string,
        sortBy: string,
        sortDirection: SortDirection,
        query: string,
        selectedFilterItems?: FilterItem[]
    ): Promise<PagePayload<TransactionRow> | null> {
        return fetchTransactions({
            ...baseVariables,
            last,
            before,
            orderBy: toTransactionOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
            accountIds: selectedFilterItems
                ? selectedFilterItems.map(item => item.id)
                : null,
        })
            .then(result => {
                return toPagePayload(result.data)
            })
            .catch(null)
    }

    function pageForwards(
        first: number,
        after: string,
        sortBy: string,
        sortDirection: SortDirection,
        query: string,
        selectedFilterItems?: FilterItem[]
    ): Promise<PagePayload<TransactionRow> | null> {
        return fetchTransactions({
            ...baseVariables,
            first,
            after,
            orderBy: toTransactionOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
            accountIds: selectedFilterItems
                ? selectedFilterItems.map(item => item.id)
                : null,
        })
            .then(result => {
                return toPagePayload(result.data)
            })
            .catch(null)
    }

    function getInitialPages(
        pageSize: number,
        sortBy: string,
        sortDirection: SortDirection,
        query: string,
        selectedFilterItems?: FilterItem[]
    ): Promise<PagePayload<TransactionRow> | null> {
        return fetchTransactions({
            ...baseVariables,
            first: pageSize,
            orderBy: toTransactionOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
            accountIds: selectedFilterItems
                ? selectedFilterItems.map(item => item.id)
                : null,
        })
            .then(result => {
                return toPagePayload(result.data)
            })
            .catch(null)
    }

    function toAccounts(accountsQuery: AccountQuery): Account[] {
        return accountsQuery.accounts.map(account => ({
            id: account.id,
            reference: account.reference,
            balance: account.currentBalance,
        }))
    }

    const {
        loading: accountsLoading,
        data: accountsData,
        error: accountsError,
    } = useAccountQuery()
    const {
        loading: transactionsLoading,
        data: transactionsData,
        error: transactionsError,
    } = useTransactionsQuery({
        variables: {
            ...baseVariables,
            first: INITIAL_PAGE_SIZE,
            orderBy: toTransactionOrderBy(INITIAL_SORT_BY),
            direction: toSortOrder(INITIAL_SORT_DIRECTION),
        },
    })

    if (accountsLoading || transactionsLoading) return <Loading />
    if (accountsError || transactionsError)
        return (
            <Error
                message={
                    "Er ging iets mis bij het ophalen van uw bankgegevens."
                }
            />
        )
    if (
        accountsData === undefined ||
        transactionsData === undefined ||
        transactionsData.transactions.pageInfo.edgeCount === null
    )
        return <Error message={"Er ging iets mis."} />

    return (
        <BankContainer
            data={{
                tableData: {
                    rows: toRows(transactionsData),
                    startCursor:
                        transactionsData.transactions.pageInfo.startCursor,
                    endCursor: transactionsData.transactions.pageInfo.endCursor,
                    rowCount: transactionsData.transactions.pageInfo.edgeCount,
                },
                accounts: toAccounts(accountsData),
            }}
            queries={{
                pageForwards,
                pageBackwards,
                getInitialPages,
            }}
        />
    )
}
