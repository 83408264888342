import { Card, CardContent } from "@material-ui/core"
import { Detail } from "../../../components/detail/detail"
import { formatCurrencyInEuro } from "../../../utils/currency-util"
import { applyFormatter } from "../../../utils/util"
import { Props } from "./types"

export function AccountsBalance(props: Props): JSX.Element {
    const { totalBalance } = props

    return (
        <Card>
            <CardContent>
                <Detail
                    currency
                    label="Totaal saldo (opgeteld)"
                    value={applyFormatter(formatCurrencyInEuro, totalBalance)}
                />
            </CardContent>
        </Card>
    )
}
