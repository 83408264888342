import { CancelablePromise } from "../../../utils/cancelable-promise"
import { PendingExpense } from "../group-pages/pending-expenses-list/pending-expense/types"
import { State as GroupPagesState } from "../group-pages/types"
import { Upload } from "../group-pages/uploads-overview/types"

export interface Props {
    readonly uploadFiles: (files: File[]) => Promise<Upload[] | null>
    readonly createExpenses: (
        pendingExpenses: PendingExpense[]
    ) => Promise<boolean>
}

export type State = ClosedState | OpenState

export enum StateType {
    Open = "open",
    Closed = "closed",
}

export interface ClosedState {
    readonly type: StateType.Closed
}

export interface OpenState {
    readonly type: StateType.Open
    readonly loading: LoadingDone | LoadingBusy | CancelableLoadingBusy
    readonly activeStep: DropZones | GroupPages
}

export enum ActiveStepStateType {
    DropZones = "dropZones",
    GroupPages = "groupFiles",
}

export interface DropZonesState {
    readonly singleExpenseFiles: File[]
    readonly multipleExpenseFiles: File[]
}

export function initialDropzonesState(): DropZonesState {
    return {
        singleExpenseFiles: [],
        multipleExpenseFiles: [],
    }
}

export interface DropZones {
    readonly type: ActiveStepStateType.DropZones
    readonly dropZonesState: DropZonesState
}

export interface GroupPages {
    readonly type: ActiveStepStateType.GroupPages
    readonly groupPagesState: GroupPagesState
    readonly previousStepState: DropZonesState
}

export enum LoadingType {
    Done = "done",
    Busy = "busy",
    CancelableBusy = "cancelabeBusy",
}

export interface LoadingDone {
    readonly type: LoadingType.Done
}
export interface LoadingBusy {
    readonly type: LoadingType.Busy
}

export interface CancelableLoadingBusy {
    readonly type: LoadingType.CancelableBusy
    readonly awaitedPromises: CancelablePromise<any>[]
}

export function initialState(): State {
    return {
        type: StateType.Closed,
    }
}
