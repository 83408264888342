import { Button, Card, CardContent, CardHeader } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React from "react"
import { Detail } from "../../../components/detail/detail"
import { BookingPeriod, Props } from "./types"
import { UpdatePontoCredentialsDialog } from "./update-ponto-credentials-dialog/update-ponto-credentials-dialog"

export function ClientProfile(props: Props): JSX.Element {
    const [dialogOpen, setDialogOpen] = React.useState(false)

    function toBookingPeriodLabel(bookingPeriod: BookingPeriod): string {
        switch (bookingPeriod) {
            case BookingPeriod.Quarterly:
                return "Per kwartaal"
            case BookingPeriod.Monthly:
                return "Maandelijks"
        }
    }

    function getMessage(): JSX.Element {
        if (!props.clientInfo) throw new Error("unreachable")
        if (
            props.clientInfo.pontoClientId === null ||
            props.clientInfo.pontoSecret === null
        )
            return (
                <Alert severity="error">
                    MyPonto credentials zijn niet volledig.{" "}
                </Alert>
            )
        if (props.clientInfo.pontoCredentialsValid === null)
            return (
                <Alert severity="info">
                    MyPonto credentials werden nog niet geverifieerd.{" "}
                </Alert>
            )
        if (props.clientInfo.pontoCredentialsValid === false)
            return (
                <Alert severity="error">
                    MyPonto credentials zijn ongeldig.
                </Alert>
            )
        return (
            <Alert severity="success">MyPonto credentials zijn geldig.</Alert>
        )
    }

    function onDialogClose(): void {
        setDialogOpen(false)
    }

    return (
        <Card>
            <CardHeader title="Bedrijfsprofiel" />
            <CardContent>
                {props.clientInfo === null ? (
                    <div>Uw bedrijfsprofiel is nog niet ingesteld.</div>
                ) : (
                    <div className="flex flex-col space-y-4">
                        <div className="grid grid-cols-3 gap-9">
                            <Detail
                                label={"Naam"}
                                value={props.clientInfo.name}
                            />
                            <Detail
                                label={"KBO nummer"}
                                value={props.clientInfo.kboNumber}
                            />
                            <Detail
                                label={"Boekperiode"}
                                value={toBookingPeriodLabel(
                                    props.clientInfo.bookingPeriod
                                )}
                            />
                            <Detail
                                label={"MyPonto CLIENT ID"}
                                value={props.clientInfo.pontoClientId}
                            />
                            <div className="col-span-2">
                                <Detail
                                    label={"MyPonto CLIENT SECRET"}
                                    value={props.clientInfo.pontoSecret}
                                />
                            </div>

                            <div className="col-span-3">{getMessage()}</div>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setDialogOpen(true)}
                            >
                                WIJZIG MYPONTO CREDENTIALS
                            </Button>
                        </div>
                        <UpdatePontoCredentialsDialog
                            open={dialogOpen}
                            pontoClientId={props.clientInfo.pontoClientId ?? ""}
                            onClose={onDialogClose}
                        />
                    </div>
                )}
            </CardContent>
        </Card>
    )
}
