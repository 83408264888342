import { useHistory } from "react-router-dom"
import logo from "../../assets/logo.png"
import { UploadFiles } from "../../components/upload-files/upload-files"
import { Routes } from "../../constants"
import { NavRailItem } from "./nav-rail-item/nav-rail-item"
import { Props, View } from "./types"

export function NavRail(props: Props): JSX.Element {
    const history = useHistory()

    function handleSelectView(newView: View): void {
        props.setSelectedView(newView)
        switch (newView) {
            case View.Expenses:
                history.push(Routes.Expenses)
                break
            case View.Bank:
                history.push(Routes.Bank)
                break
            case View.Profile:
                history.push(Routes.Profile)
        }
    }

    return (
        <div className="flex flex-col h-full justify-between items-center">
            <UploadFiles />
            <div className="flex flex-col space-y-4">
                <NavRailItem
                    label="UITGAVEN"
                    icon="file_upload"
                    selected={props.selectedView === View.Expenses}
                    onClick={() => handleSelectView(View.Expenses)}
                />
                <NavRailItem
                    label="BANK"
                    icon="account_balance"
                    selected={props.selectedView === View.Bank}
                    onClick={() => handleSelectView(View.Bank)}
                />
                <NavRailItem
                    label="PROFIEL"
                    icon="person"
                    selected={props.selectedView === View.Profile}
                    onClick={() => handleSelectView(View.Profile)}
                />
            </div>
            <div className="w-20">
                <img className="object-contain" src={logo} alt="UNIAC Logo" />
            </div>
        </div>
    )
}
