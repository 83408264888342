import { State as EditModeState } from "./edit-mode/types"
import { ExpenseType, VatRegime } from "../../../generated/graphql"

export interface ExpenseOverviewInfo {
    readonly type: ExpenseType | null
    readonly supplier: string | null
    readonly reference: string | null
    readonly date: Date | null
    readonly expirationDate: Date | null
    readonly periodStart: Date | null
    readonly periodEnd: Date | null
    readonly description: string | null
    readonly grossAmount: number | null
    readonly netAmount: number | null
    readonly deductibleVat: number | null
    readonly nonDeductiblePrivateVat: number | null
    readonly nonDeductibleCorporateVat: number | null
    readonly currency: string | null
    readonly totalForeignCurrency: number | null
    readonly exchangeRate: number | null
    readonly discount: number | null
    readonly deposit: number | null
    readonly vatRegime: VatRegime | null
    readonly docString: string | null
    readonly isPaid: boolean
}

export enum Type {
    View = "View",
    Edit = "Edit",
}

export interface ViewMode {
    readonly type: Type.View
}

export interface EditMode {
    readonly type: Type.Edit
    readonly editModeState: EditModeState
}

export interface State {
    readonly expenseOverviewInfo: ExpenseOverviewInfo
    readonly mode: ViewMode | EditMode
    readonly loading: boolean
}

export interface Props {
    readonly state: State
    readonly setState: (state: State) => void
    readonly editExpenseOverViewInfo: (
        newExpenseOverViewInfo: ExpenseOverviewInfo
    ) => Promise<boolean>
}

export function initialState(expenseOverviewInfo: ExpenseOverviewInfo): State {
    return {
        expenseOverviewInfo,
        mode: { type: Type.View },
        loading: false,
    }
}
