import {
    FilterItem,
    GetInitialPages,
    PageBackwards,
    PageForwards,
    PagePayload,
} from "../../../components/table/types"
import {
    initialState as initialTransactionTableState,
    State as TransactionTableState,
    TransactionRow,
} from "../transaction-table/types"

export interface Data {
    readonly tableData: TableData
    readonly accounts: Account[]
}

export interface Account {
    readonly id: string
    readonly reference: string
    readonly balance: number
}

export interface TableData {
    readonly rows: TransactionRow[]
    readonly rowCount: number
    readonly startCursor: string
    readonly endCursor: string
}

export interface Queries {
    readonly pageBackwards: PageBackwards<PagePayload<TransactionRow>>
    readonly pageForwards: PageForwards<PagePayload<TransactionRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<TransactionRow>>
}

export interface State {
    readonly transactionTableState: TransactionTableState
}

export interface Props {
    data: Data
    queries: Queries
}

export function initialState(
    rows: TransactionRow[],
    totalRowCount: number,
    startCursor: string,
    endCursor: string,
    selectedFilterItems: FilterItem[]
): State {
    return {
        transactionTableState: initialTransactionTableState(
            rows,
            totalRowCount,
            startCursor,
            endCursor,
            selectedFilterItems
        ),
    }
}
