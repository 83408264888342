import { createTheme } from "@material-ui/core/styles"

export const theme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#3383F3",
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#848489",
        },
        background: {
            default: "#33333C",
            paper: "#3C3C44",
        },
        success: {
            main: "#57B684",
        },
    },
    typography: {
        allVariants: {
            color: "white",
        },
        fontFamily: "Roboto Condensed",
        body1: {
            fontSize: "0.875rem",
        },
    },
})
