import { ExpenseRow } from "./expense-table/types"
import { ExpensesOrderBy, ExpensesQuery } from "../../generated/graphql"
import { isoStringToDate } from "../../utils/date-util"
import { PagePayload } from "../../components/table/types"

export const INITIAL_PAGE_SIZE = 10

export function toRows(expensesQuery: ExpensesQuery): ExpenseRow[] {
    return expensesQuery.expenses.edges.map(edge => ({
        id: edge.node.id,
        bookingPeriod: edge.node.bookingPeriod,
        isPaid: edge.node.isPaid,
        type: edge.node.type,
        date: isoStringToDate(edge.node.date),
        supplier: edge.node.supplier,
        grossAmount: edge.node.grossAmount,
        netAmount: edge.node.netAmount,
        reference: edge.node.reference,
        docString: edge.node.docString,
    }))
}

export function toPagePayload(
    expensesQuery: ExpensesQuery
): PagePayload<ExpenseRow> | null {
    const edgeCount = expensesQuery.expenses.pageInfo.edgeCount
    return edgeCount === null
        ? null
        : {
              rows: toRows(expensesQuery),
              startCursor: expensesQuery.expenses.pageInfo.startCursor,
              endCursor: expensesQuery.expenses.pageInfo.endCursor,
              rowCount: edgeCount,
          }
}

export function toExpenseOrderBy(column: string): ExpensesOrderBy {
    switch (column) {
        case "id":
            return ExpensesOrderBy.Id
        case "date":
            return ExpensesOrderBy.Date
        case "grossAmount":
            return ExpensesOrderBy.GrossAmount
        case "supplier":
            return ExpensesOrderBy.Supplier
        default:
            throw new Error("Received an unexpected sortable column: " + column)
    }
}
