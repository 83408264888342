import { ExpenseRow } from "../expense-table/types"
import { State as ExpenseTableState } from "../expense-table/types"
import { initialState as initialExpenseTableState } from "../expense-table/types"
import { BookingPeriod } from "../../../generated/graphql"
import {
    GetInitialPages,
    PageBackwards,
    PageForwards,
    PagePayload,
} from "../../../components/table/types"

export interface Data {
    readonly rows: ExpenseRow[]
    readonly rowCount: number
    readonly bookingPeriod: BookingPeriod
    readonly startCursor: string
    readonly endCursor: string
}

export interface Queries {
    readonly pageBackwards: PageBackwards<PagePayload<ExpenseRow>>
    readonly pageForwards: PageForwards<PagePayload<ExpenseRow>>
    readonly getInitialPages: GetInitialPages<PagePayload<ExpenseRow>>
}

export interface Props {
    readonly data: Data
    readonly queries: Queries
}

export interface State {
    readonly expenseTableState: ExpenseTableState
}

export function initialState(
    rows: ExpenseRow[],
    rowCount: number,
    startCursor: string,
    endCursor: string
): State {
    return {
        expenseTableState: initialExpenseTableState(
            rows,
            rowCount,
            startCursor,
            endCursor
        ),
    }
}
