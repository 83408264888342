import {
    useBookingPeriodQuery,
    useExpensesQuery,
} from "../../generated/graphql"
import { INITIAL_SORT_BY, ExpenseRow } from "./expense-table/types"
import { Loading } from "../../components/loading"
import { Error } from "../../components/error/error"
import { ExpensesContainer } from "./expenses-container/expenses-container"
import {
    INITIAL_PAGE_SIZE,
    toExpenseOrderBy,
    toPagePayload,
    toRows,
} from "./types"
import {
    INITIAL_SORT_DIRECTION,
    PagePayload,
    SortDirection,
    toSortOrder,
} from "../../components/table/types"

const baseVariables = {
    last: null,
    before: null,
    first: null,
    after: null,
    orderBy: null,
    direction: null,
    query: null,
}

export function Expenses(): JSX.Element {
    const { refetch: fetchExpenses } = useExpensesQuery({ skip: true })

    function pageBackwards(
        last: number,
        before: string,
        sortBy: string,
        sortDirection: SortDirection,
        query: string
    ): Promise<PagePayload<ExpenseRow> | null> {
        return fetchExpenses({
            ...baseVariables,
            last,
            before,
            orderBy: toExpenseOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
        })
            .then(result => toPagePayload(result.data))
            .catch(null)
    }

    function pageForwards(
        first: number,
        after: string,
        sortBy: string,
        sortDirection: SortDirection,
        query: string
    ): Promise<PagePayload<ExpenseRow> | null> {
        return fetchExpenses({
            ...baseVariables,
            first,
            after,
            orderBy: toExpenseOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
        })
            .then(result => toPagePayload(result.data))
            .catch(null)
    }

    function getInitialPages(
        pageSize: number,
        sortBy: string,
        sortDirection: SortDirection,
        query: string
    ): Promise<PagePayload<ExpenseRow> | null> {
        return fetchExpenses({
            ...baseVariables,
            first: pageSize,
            orderBy: toExpenseOrderBy(sortBy),
            direction: toSortOrder(sortDirection),
            query,
        })
            .then(result => {
                return toPagePayload(result.data)
            })
            .catch(null)
    }

    const { data, loading, error } = useExpensesQuery({
        variables: {
            ...baseVariables,
            first: INITIAL_PAGE_SIZE,
            orderBy: toExpenseOrderBy(INITIAL_SORT_BY),
            direction: toSortOrder(INITIAL_SORT_DIRECTION),
            query: "",
        },
    })

    const {
        data: bookingPeriodData,
        loading: bookingPeriodLoading,
        error: bookingPeriodError,
    } = useBookingPeriodQuery()

    if (loading || bookingPeriodLoading) return <Loading />
    if (error) return <Error message="We kunnen de uitgaven niet weergeven." />
    if (bookingPeriodError)
        return (
            <Error message="Er ging iets mis bij het ophalen van uw bedrijfsinformatie." />
        )
    if (
        data === undefined ||
        data.expenses.pageInfo.edgeCount === null ||
        bookingPeriodData === undefined ||
        bookingPeriodData.client === null
    )
        return <Error message="Er ging iets mis." />

    console.log(data)

    return (
        <ExpensesContainer
            data={{
                rows: toRows(data),
                rowCount: data.expenses.pageInfo.edgeCount,
                bookingPeriod: bookingPeriodData.client.bookingPeriod,
                startCursor: data.expenses.pageInfo.startCursor,
                endCursor: data.expenses.pageInfo.endCursor,
            }}
            queries={{
                pageForwards,
                pageBackwards,
                getInitialPages,
            }}
        />
    )
}
