import { Button, Card, CardContent, Link, Typography } from "@material-ui/core"
import { Props } from "./types"
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid"
import IconButton from "@material-ui/core/IconButton"
import { history } from "../../../providers/browser-history"
import { routeToExpense } from "../../../constants"
import {
    cellRenderer,
    currencyFormatter,
    dateFormatter,
    headerRenderer,
    useStyles,
} from "../../../components/table/table"
import { CellType } from "../../../components/table/types"
import { EditLinkedExpensesDialog } from "../edit-linked-expenses-dialog/edit-linked-expenses-dialog"
import {
    initialState as initialLinkedExpensesDialogState,
    State as EditLinkedExpensesDialogState,
} from "../edit-linked-expenses-dialog/types"

export function LinkedExpenses(props: Props): JSX.Element {
    const {
        state,
        setState,
        transactionOverview,
        getInitialPages,
        pageBackwards,
        pageForwards,
        deleteLinkedExpense,
        addLinkedExpenses,
    } = props

    const columns: GridColDef[] = [
        {
            field: "id",
            sortable: false,
            disableColumnMenu: true,
            renderCell: params => (
                <div className="m-auto">
                    <IconButton
                        onClick={() => history.push(routeToExpense(params.id))}
                    >
                        <span className="material-icons-outlined">
                            summarize
                        </span>
                    </IconButton>
                </div>
            ),
            renderHeader: () => <div />,
        },
        {
            field: "docString",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Factuurnummer",
            flex: 1.5,
            renderCell: params => cellRenderer(params, CellType.Normal),
            renderHeader: headerRenderer,
        },
        {
            field: "supplier",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Leverancier",
            flex: 1.5,
            renderCell: params => cellRenderer(params, CellType.Normal),
            renderHeader: headerRenderer,
        },
        {
            field: "date",
            headerName: "Datum document",
            sortable: false,
            disableColumnMenu: true,
            flex: 1.5,
            valueFormatter: dateFormatter,
            renderCell: params => cellRenderer(params, CellType.Normal),
            renderHeader: headerRenderer,
        },
        {
            field: "grossAmount",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Bruto",
            flex: 1,
            valueFormatter: currencyFormatter,
            renderCell: params => cellRenderer(params, CellType.Currency),
            renderHeader: headerRenderer,
        },
        {
            field: "delete",
            sortable: false,
            disableColumnMenu: true,
            renderCell: params => (
                <div className="m-auto">
                    <IconButton
                        onClick={() => onDeleteClick(params.id.toString())}
                    >
                        <span className="material-icons-outlined">delete</span>
                    </IconButton>
                </div>
            ),
            renderHeader: () => <div />,
        },
    ]

    function onSelectionModelChange(selectionModel: GridRowId[]): void {
        if (selectionModel.length === 0) {
            setState({ ...state, selectedExpense: null })
        } else {
            const selectedExpenseId = selectionModel[0].toString()
            const selectedExpense = state.linkedExpenses.find(
                e => e.id === selectedExpenseId
            )

            setState({
                ...state,
                selectedExpense:
                    selectedExpense === undefined ? null : selectedExpense,
            })
        }
    }

    function onDeleteClick(id: string): void {
        deleteLinkedExpense([id]).then(success => {
            if (success) {
                setState({
                    ...state,
                    linkedExpenses: state.linkedExpenses.filter(
                        le => le.id !== id
                    ),
                })
            }
        })
    }

    function onEditClick(): void {
        setState({
            ...state,
            dialogOpen: true,
        })
    }

    function onCancel(): void {
        setState({
            ...state,
            editLinkedExpensesDialogState: initialLinkedExpensesDialogState(),
            dialogOpen: false,
        })
    }

    function onSave(ids: string[]): void {
        addLinkedExpenses(ids).then(res => {
            if (res.success) {
                setState({
                    ...state,
                    linkedExpenses: res.expenses,
                    editLinkedExpensesDialogState:
                        initialLinkedExpensesDialogState(),
                    dialogOpen: false,
                })
            } else {
                setState({
                    ...state,
                    editLinkedExpensesDialogState:
                        initialLinkedExpensesDialogState(),
                    dialogOpen: false,
                })
                alert(
                    "Er ging iets mis bij het koppelen van de uitgaven, probeer het later opnieuw."
                )
            }
        })
    }

    function setEditLinkedExpensesDialogState(
        editLinkedExpensesDialogState: EditLinkedExpensesDialogState
    ): void {
        setState({
            ...state,
            editLinkedExpensesDialogState,
        })
    }

    const classes = useStyles()

    return (
        <Card>
            <div className="flex flex-row justify-between m-4">
                <Typography variant="h5">Gekoppelde uitgaven</Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onEditClick}
                >
                    VOEG TOE
                </Button>
            </div>{" "}
            <CardContent>
                {state.linkedExpenses.length === 0 ? (
                    <p className="text-center">
                        Deze transactie heeft nog geen gekoppelde uitgaven, u
                        kan er{" "}
                        <Link className="cursor-pointer" onClick={onEditClick}>
                            toevoegen
                        </Link>
                        .
                    </p>
                ) : (
                    <DataGrid
                        classes={classes}
                        columns={columns}
                        rows={state.linkedExpenses.map(e => ({
                            id: e.id,
                            docString: e.docString,
                            supplier: e.supplier,
                            date: e.date,
                            grossAmount: e.grossAmount,
                        }))}
                        onSelectionModelChange={onSelectionModelChange}
                        selectionModel={
                            state.selectedExpense === null
                                ? []
                                : [state.selectedExpense.id]
                        }
                        autoHeight
                        hideFooter
                    />
                )}
            </CardContent>
            <EditLinkedExpensesDialog
                state={state.editLinkedExpensesDialogState}
                setState={setEditLinkedExpensesDialogState}
                previouslyLinkedExpenseIds={state.linkedExpenses.map(
                    le => le.id
                )}
                open={state.dialogOpen}
                onCancel={onCancel}
                onSave={onSave}
                transactionOverview={transactionOverview}
                pageBackwards={pageBackwards}
                pageForwards={pageForwards}
                getInitialPages={getInitialPages}
            />
        </Card>
    )
}
