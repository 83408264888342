import { View } from "../nav-rail/types"

export interface State {
    view: View
}

export function initialState(): State {
    return {
        view: View.Expenses,
    }
}
