export enum Routes {
    Root = "/",
    Login = "/login",
    Expenses = "/uitgaven",
    Expense = "/uitgave/:expenseId",
    Transaction = "/transactie/:transactionId",
    Bank = "/bank",
    Profile = "/profiel",
}

export function routeToExpense(id: string | number): string {
    return `/uitgave/${id}`
}

export function routeToTransaction(id: string | number): string {
    return `/transactie/${id}`
}
