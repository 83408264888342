import {
    cellRenderer,
    dateFormatter,
    headerRenderer,
    Table,
} from "../../../components/table/table"
import { GridColDef } from "@mui/x-data-grid"
import {
    CellType,
    INITIAL_SORT_DIRECTION,
    initialState as initialTableState,
    SortableColumn,
    State as TableState,
} from "../../../components/table/types"
import {
    INITIAL_PAGE_SIZE,
    INITIAL_SORT_BY,
    LinkedExpenseRow,
    Props,
    Type,
} from "./types"
import { Loading } from "../../../components/loading"
import { useEffect } from "react"
import { Error as ErrorComponent } from "../../../components/error/error"
import { Checkbox } from "@material-ui/core"

const sortableColumns: SortableColumn[] = [
    {
        column: "id",
        label: "Laatst toegevoegd",
    },
    {
        column: "supplier",
        label: "Leverancier",
    },
    {
        column: "date",
        label: "Datum",
    },
    {
        column: "grossAmount",
        label: "Bruto",
    },
]

export function AddLinkedExpensesTable(props: Props): JSX.Element {
    const {
        state,
        setState,
        pageBackwards,
        pageForwards,
        getInitialPages,
        setSelectedExpensePDFUrl,
        selectedExpenseIds,
        setSelectedExpenseIds,
        previouslyLinkedExpenseIds,
    } = props

    const columns: GridColDef[] = [
        {
            field: "id",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Koppelen",
            flex: 1,
            renderCell: params => (
                <div className="m-auto">
                    <Checkbox
                        color="primary"
                        onChange={e =>
                            onCheckChange(
                                params.id.toString(),
                                e.target.checked
                            )
                        }
                        checked={isChecked(params.id.toString())}
                        disabled={isDisabled(params.id.toString())}
                    />
                </div>
            ),
            renderHeader: headerRenderer,
        },
        {
            field: "docString",
            sortable: false,
            flex: 1,
            disableColumnMenu: true,
            headerName: "Factuurnummer",
            renderCell: params => cellRenderer(params, CellType.Normal),
            renderHeader: headerRenderer,
        },
        {
            field: "date",
            sortable: false,
            flex: 1,
            disableColumnMenu: true,
            valueFormatter: dateFormatter,
            headerName: "Datum document",
            renderCell: params => cellRenderer(params, CellType.Normal),
            renderHeader: headerRenderer,
        },
        {
            field: "supplier",
            sortable: false,
            flex: 1,
            disableColumnMenu: true,
            headerName: "Leverancier",
            renderCell: params => cellRenderer(params, CellType.Normal),
            renderHeader: headerRenderer,
        },
        {
            field: "grossAmount",
            sortable: false,
            flex: 1,
            disableColumnMenu: true,
            headerName: "Bruto",
            renderCell: params => cellRenderer(params, CellType.Normal),
            renderHeader: headerRenderer,
        },
    ]

    useEffect(() => {
        getInitialPages(
            INITIAL_PAGE_SIZE,
            INITIAL_SORT_BY,
            INITIAL_SORT_DIRECTION,
            ""
        ).then(result => {
            if (result === null) {
                setState({
                    type: Type.Error,
                })
            } else {
                setState({
                    type: Type.Loaded,
                    tableState: initialTableState(
                        result.rows,
                        INITIAL_PAGE_SIZE,
                        result.rowCount,
                        INITIAL_SORT_BY,
                        result.startCursor,
                        result.endCursor
                    ),
                })
            }
        })
    }, [])

    function isDisabled(id: string): boolean {
        return previouslyLinkedExpenseIds.some(pid => pid === id)
    }

    function isChecked(id: string): boolean {
        if (state.type === Type.Unloaded || state.type === Type.Error)
            throw new Error("Unreachable")
        return (
            selectedExpenseIds.some(se => se === id) ||
            previouslyLinkedExpenseIds.some(pid => pid === id)
        )
    }

    function onCheckChange(id: string, checked: boolean): void {
        if (state.type === Type.Unloaded || state.type === Type.Error)
            throw new Error("Unreachable")
        setSelectedExpenseIds(
            checked
                ? [...selectedExpenseIds, id]
                : selectedExpenseIds.filter(se => se !== id)
        )
    }

    function setTableState(tableState: TableState<LinkedExpenseRow>): void {
        if (state.type === Type.Unloaded || state.type === Type.Error)
            throw new Error("Unreachable")
        setState({
            ...state,
            tableState,
        })
    }

    function onSelectChange(id: string | null): void {
        if (state.type === Type.Unloaded || state.type === Type.Error)
            throw new Error("Unreachable")
        if (id === null) {
            setSelectedExpensePDFUrl(null)
        } else {
            const selectedRow = state.tableState.rows.find(r => r.id === id)
            setSelectedExpensePDFUrl(
                selectedRow === undefined ? null : selectedRow.url
            )
        }
    }

    switch (state.type) {
        case Type.Loaded:
            return (
                <Table
                    columns={columns}
                    sortableColumns={sortableColumns}
                    setState={setTableState}
                    state={state.tableState}
                    pageBackwards={pageBackwards}
                    pageForwards={pageForwards}
                    getInitialPages={getInitialPages}
                    searchable
                    enableRowSelection
                    onSelectionChange={onSelectChange}
                />
            )
        case Type.Unloaded:
            return <Loading />
        case Type.Error:
            return (
                <ErrorComponent message="Er ging iets mis bij het ophalen van de uitgaven." />
            )
    }
}
