import { Action } from "../../upload-files-container/reducer"
import { PendingExpense } from "./pending-expense/types"
import { Dispatch } from "react"

export interface Props {
    readonly state: State
    readonly dispatch: Dispatch<Action>
}

export interface State {
    readonly pendingExpenses: PendingExpense[]
}

export function initialState(pendingExpenses: PendingExpense[]): State {
    return { pendingExpenses }
}
