import Divider from "@material-ui/core/Divider"
import { PagePreview } from "./page-preview/page-preview"
import { Props } from "./types"
import Button from "@material-ui/core/Button"
import { ActionType } from "../../upload-files-container/reducer"

export function UploadsOverview(props: Props): JSX.Element {
    return (
        <div className="flex flex-col space-y-4 min-h-0 overflow-scroll p-4">
            {props.uploads
                .filter(u => u.pages.length > 0)
                .flatMap(upload => [
                    <Divider
                        key={`${upload.id}-divider`}
                        variant="fullWidth"
                    />,
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-row items-center justify-between">
                            <div>{upload.name}</div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    props.dispatch({
                                        type: ActionType.MAKE_EXPENSE,
                                        payload: {
                                            id: upload.id,
                                            pages: upload.pages,
                                        },
                                    })
                                }
                            >
                                MAAK UITGAVE
                            </Button>
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            {upload.pages.map(p => (
                                <PagePreview key={p.id} page={p} />
                            ))}
                        </div>
                    </div>,
                ])
                .slice(1)}
        </div>
    )
}
