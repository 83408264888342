import { Divider } from "@material-ui/core"
import { NonDeductibleVatDetail } from "./non-deductible-vat-detail/non-deductible-vat-detail"
import { Detail } from "../../../../components/detail/detail"
import {
    formatCurrencyInEuro,
    formatForeignValuta,
} from "../../../../utils/currency-util"
import { ExpenseType } from "../../../../generated/graphql"
import { formatDate } from "../../../../utils/date-util"
import { applyFormatter } from "../../../../utils/util"
import { vatRegimeToString } from "../../../../utils/vat-regime-util"
import { amountToString } from "../../../../utils/amount-util"
import { Props } from "./types"

function formatExpenseType(expenseType: ExpenseType): string {
    switch (expenseType) {
        case ExpenseType.Af:
            return "Factuur"
        case ExpenseType.Cn:
            return "Creditnota"
    }
}

function periodToString(start: Date | null, end: Date | null): string | null {
    if (start === null || end === null) return null
    return `${formatDate(start)} - ${formatDate(end)}`
}

function foreignValutaToString(
    currency: string | null,
    totalForeignCurrency: number | null
): string | null {
    if (currency === null || totalForeignCurrency === null) {
        return null
    } else {
        return formatForeignValuta(currency, totalForeignCurrency)
    }
}

export function ViewMode(props: Props): JSX.Element {
    const expenseOverviewInfo = props.expenseOverviewInfo
    const hasForeignCurrency = expenseOverviewInfo.totalForeignCurrency !== null
    return (
        <div className="flex flex-col space-y-4">
            <div className=" grid grid-cols-4 gap-9">
                <Detail
                    label="Type"
                    value={applyFormatter(
                        formatExpenseType,
                        expenseOverviewInfo.type
                    )}
                />
                <Detail
                    label="Leverancier"
                    value={expenseOverviewInfo.supplier}
                />
                <Detail
                    label="Referentie"
                    value={expenseOverviewInfo.reference}
                />
                <Detail
                    label="Datum"
                    value={applyFormatter(formatDate, expenseOverviewInfo.date)}
                />
                <Detail
                    label="Vervaldatum"
                    value={applyFormatter(
                        formatDate,
                        expenseOverviewInfo.expirationDate
                    )}
                />
                <Detail
                    label="Periode"
                    value={periodToString(
                        expenseOverviewInfo.periodStart,
                        expenseOverviewInfo.periodEnd
                    )}
                />
                <Detail
                    label="Omschrijving"
                    value={expenseOverviewInfo.description}
                />
                <Detail
                    label="Factuurnummer"
                    value={expenseOverviewInfo.docString}
                />
            </div>
            <Divider light />
            <div className=" grid grid-cols-2 gap-9">
                <Detail
                    label="Bruto"
                    value={applyFormatter(
                        formatCurrencyInEuro,
                        expenseOverviewInfo.grossAmount
                    )}
                    currency
                />
                <Detail
                    label="Netto"
                    value={applyFormatter(
                        formatCurrencyInEuro,
                        expenseOverviewInfo.netAmount
                    )}
                    currency
                />
                <Detail
                    label="Aftrekbare BTW"
                    value={applyFormatter(
                        formatCurrencyInEuro,
                        expenseOverviewInfo.deductibleVat
                    )}
                    currency
                />
                <NonDeductibleVatDetail
                    private={expenseOverviewInfo.nonDeductiblePrivateVat}
                    corporate={expenseOverviewInfo.nonDeductibleCorporateVat}
                />
            </div>
            <Divider light />
            <div className=" grid grid-cols-4 gap-9">
                {hasForeignCurrency && (
                    <Detail
                        label="Koers"
                        value={
                            expenseOverviewInfo.exchangeRate === null
                                ? null
                                : amountToString(
                                      expenseOverviewInfo.exchangeRate
                                  )
                        }
                        currency
                    />
                )}
                {hasForeignCurrency && (
                    <Detail
                        label="Totaal Vreemd Valuta"
                        value={foreignValutaToString(
                            expenseOverviewInfo.currency,
                            expenseOverviewInfo.totalForeignCurrency
                        )}
                        currency
                    />
                )}
                <Detail
                    label="Disconto"
                    value={applyFormatter(
                        formatCurrencyInEuro,
                        expenseOverviewInfo.discount
                    )}
                    currency
                />
                <Detail
                    label="Leeggoed"
                    value={applyFormatter(
                        formatCurrencyInEuro,
                        expenseOverviewInfo.deposit
                    )}
                    currency
                />
                <Detail
                    label="BTW Regime"
                    value={applyFormatter(
                        vatRegimeToString,
                        expenseOverviewInfo.vatRegime
                    )}
                />
            </div>
        </div>
    )
}
