import axios from "axios"
import { AuthorizationHeader } from "./types"

export function login(email: string, password: string): Promise<boolean> {
    return axios
        .post(
            process.env.REACT_APP_BACKEND_URL + "auth/login",
            {
                username: email,
                password,
            },
            { withCredentials: true }
        )
        .then(response => {
            if (!response.data.accessToken) {
                return false
            }
            localStorage.setItem("accessToken", response.data.accessToken)
            return true
        })
        .catch(() => false)
}

export function logout(): Promise<boolean> {
    localStorage.removeItem("accessToken")
    return axios
        .delete(process.env.REACT_APP_BACKEND_URL + "auth/refresh", {
            withCredentials: true,
        })
        .then(response => response.status === 200)
        .catch(() => false)
}

export function isAuthenticated(): boolean {
    return localStorage.getItem("accessToken") !== null
}

export function authHeader(): AuthorizationHeader {
    const accessToken = localStorage.getItem("accessToken")
    if (accessToken !== null) {
        return { Authorization: "Bearer " + accessToken }
    } else {
        return {}
    }
}
