import { Card, CardContent, CardHeader } from "@material-ui/core"
import { Detail } from "../../../components/detail/detail"
import React from "react"
import { Props } from "./types"

export function UserProfile(props: Props): JSX.Element {
    return (
        <Card>
            <CardHeader title="Gebruikersprofiel" />
            <CardContent>
                {props.userInfo === null ? (
                    <div>Uw gebruikersprofiel is nog niet ingesteld.</div>
                ) : (
                    <div className="grid grid-cols-3 gap-9">
                        <Detail
                            label={"Voornaam"}
                            value={props.userInfo.firstName}
                        />
                        <Detail
                            label={"Achternaam"}
                            value={props.userInfo.lastName}
                        />
                        <Detail label={"Email"} value={props.userInfo.email} />
                    </div>
                )}
            </CardContent>
        </Card>
    )
}
