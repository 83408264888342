import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core"
import React from "react"
import { Props } from "./types"

export function FilterDialog(props: Props): JSX.Element {
    const [selectedItems, setSelectedItems] = React.useState(
        props.selectedItems
    )

    function onCancel(): void {
        setSelectedItems(props.selectedItems)
        props.onCancel()
    }

    return (
        <div>
            <Dialog open={props.open} onClose={onCancel}>
                <DialogTitle>Selecteer rekeningen</DialogTitle>
                <DialogContent>
                    <div className="m-4">
                        <List>
                            {props.availableItems.map(item => (
                                <ListItem
                                    key={item.id}
                                    onClick={() => {
                                        if (
                                            selectedItems.some(
                                                i => i.id === item.id
                                            )
                                        )
                                            setSelectedItems(
                                                selectedItems.filter(
                                                    i => i.id !== item.id
                                                )
                                            )
                                        else
                                            setSelectedItems([
                                                ...selectedItems,
                                                item,
                                            ])
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            tabIndex={-1}
                                            color="primary"
                                            checked={selectedItems.some(
                                                i => i.id === item.id
                                            )}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onCancel()}>ANNULEER</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.onSave(selectedItems)}
                    >
                        OPSLAAN
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
