import { Action } from "../upload-files-container/reducer"
import {
    initialState as initialPendingExpensesState,
    State as PendingExpensesState,
} from "./pending-expenses-list/types"
import { Upload } from "./uploads-overview/types"
import { PendingExpense } from "./pending-expenses-list/pending-expense/types"
import { Dispatch } from "react"

export const PageDraggableType = "page"

export interface Props {
    readonly state: State
    readonly dispatch: Dispatch<Action>
}

export interface State {
    readonly uploads: Upload[]
    readonly pendingExpensesState: PendingExpensesState
}

export function initialState(uploadResult: {
    uploads: Upload[]
    pendingExpenses: PendingExpense[]
}): State {
    return {
        uploads: uploadResult.uploads,
        pendingExpensesState: initialPendingExpensesState(
            uploadResult.pendingExpenses
        ),
    }
}
