import { ActionType } from "../../upload-files-container/reducer"
import { PendingExpense } from "./pending-expense/pending-expense"
import { Props } from "./types"

export function PendingExpensesList(props: Props): JSX.Element {
    const { state, dispatch } = props
    return (
        <div className="grid grid-cols-3 gap-4 p-4">
            <div className="h-52">
                <PendingExpense
                    onDrop={page =>
                        dispatch({
                            type: ActionType.MOVE_PAGE,
                            payload: { page, index: "new" },
                        })
                    }
                    onCancel={() => {}}
                />
            </div>
            {state.pendingExpenses.map((e, index) => (
                <div key={index} className="h-52">
                    <PendingExpense
                        pendingExpense={e}
                        onDrop={page =>
                            dispatch({
                                type: ActionType.MOVE_PAGE,
                                payload: { page, index },
                            })
                        }
                        onCancel={() =>
                            dispatch({
                                type: ActionType.CANCEL_PENDING_EXPENSE,
                                payload: {
                                    pendingExpenseIndex: index,
                                    pages: e.pages,
                                },
                            })
                        }
                    />
                </div>
            ))}
        </div>
    )
}
