import { Category, CategoryAssignment } from "../types"

export interface PendingCategoryAssignment {
    readonly category: Category | null
    readonly amount: number | null
}

export interface State {
    readonly pendingCategoryAssignments: PendingCategoryAssignment[]
    readonly loadingCategories: boolean
    readonly categoryOptions: Category[]
}

export interface Props {
    readonly state: State
    readonly setState: (newState: State) => void
    readonly onSave: (categoryAssignments: CategoryAssignment[]) => void
    readonly onCancel: () => void
    readonly getCategories: () => Promise<Category[] | null>
}

export function initialState(categoryAssignments: CategoryAssignment[]): State {
    return {
        pendingCategoryAssignments: categoryAssignments,
        loadingCategories: false,
        categoryOptions: [],
    }
}
