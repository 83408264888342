import { useState } from "react"
import { useHistory } from "react-router-dom"

import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import CircularProgress from "@material-ui/core/CircularProgress"
import Snackbar from "@material-ui/core/Snackbar"

import logoWithTagline from "../../assets/logo_tagline.png"
import { login } from "../../services/auth-service/auth.service"
import { State } from "./types"
import { Routes } from "../../constants"

export function Login(): JSX.Element {
    const [state, setState] = useState<State>(State.Normal)
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const history = useHistory()

    function validateForm(): boolean {
        return email.length > 0 && password.length > 0
    }

    function handleLogin(): void {
        setState(State.BusyLoggingIn)
        login(email, password).then(succeeded => {
            if (succeeded) history.push(Routes.Expenses)
            else setState(State.LoginFailed)
        })
    }

    return (
        <div className="flex w-screen h-screen bg-background items-center">
            <div className="flex flex-row w-full h-5/6">
                <div className="flex flex-1 h-full items-center justify-center">
                    <div className="w-96">
                        <img
                            className="object-contain"
                            src={logoWithTagline}
                            alt="UNIAC Logo"
                        />
                    </div>
                </div>
                <Divider orientation="vertical" />
                <div className="flex flex-1 h-full items-center justify-center">
                    <form
                        className="flex flex-col space-y-6 w-72"
                        onSubmit={e => {
                            e.preventDefault()
                            handleLogin()
                        }}
                    >
                        <TextField
                            label="Email"
                            variant="outlined"
                            color="primary"
                            type="email"
                            error={state === State.LoginFailed}
                            onChange={e => {
                                setEmail(e.target.value)
                            }}
                            onFocus={() => setState(State.Normal)}
                        />
                        <TextField
                            label="Wachtwoord"
                            variant="outlined"
                            color="primary"
                            type="password"
                            error={state === State.LoginFailed}
                            onChange={e => {
                                setPassword(e.target.value)
                            }}
                            onFocus={() => setState(State.Normal)}
                        />
                        <Button
                            disabled={!validateForm()}
                            type="submit"
                            size="large"
                            variant="contained"
                            color="primary"
                        >
                            LOG IN
                        </Button>
                    </form>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        open={state === State.LoginFailed}
                        autoHideDuration={6000}
                        message="Inloggen mislukt. Controleer email adres en wachtwoord."
                    />
                </div>
            </div>
            {state === State.BusyLoggingIn && (
                <div className="flex fixed top-0 left-0 w-screen h-screen z-10 backdrop-filter backdrop-blur justify-center items-center">
                    <Card className="flex flex-row h-20 items-center justify-center space-x-5 p-5">
                        <CircularProgress />
                        <h1> Inloggen... </h1>
                    </Card>
                </div>
            )}
        </div>
    )
}
