import { useState } from "react"
import { Props } from "./types"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import IconButton from "@material-ui/core/IconButton"
import NavigateNext from "@material-ui/icons/NavigateNextRounded"
import NavigateBefore from "@material-ui/icons/NavigateBeforeRounded"
import { SizeMe } from "react-sizeme"
import { Error } from "../../../components/error/error"
import { Loading } from "../../../components/loading"
import { pdfjs } from "react-pdf"

// https://github.com/wojtekmaj/react-pdf#create-react-app
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export function ExpensePDF(props: Props): JSX.Element {
    const [page, setPage] = useState(1)
    const [nbPages, setNbPages] = useState(1)
    const [loaded, setLoaded] = useState(false)

    return (
        <div className="flex flex-col w-full h-full items-center space-y-4">
            <SizeMe monitorHeight>
                {({ size }) => (
                    <div className="flex-grow min-h-0">
                        <Document
                            className="h-full"
                            file={props.url}
                            error={
                                <Error message="We kunnen deze PDF niet weergeven." />
                            }
                            loading={<Loading />}
                            onLoadSuccess={pdf => {
                                setNbPages(pdf.numPages)
                                setLoaded(true)
                            }}
                        >
                            <Page
                                height={size.height ? size.height : undefined}
                                pageNumber={page}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                    </div>
                )}
            </SizeMe>

            {loaded && (
                <div className="flex flex-row items-center space-x-4">
                    {nbPages > 1 && (
                        <IconButton
                            size="small"
                            onClick={() => setPage(page - 1)}
                            disabled={page === 1}
                        >
                            <NavigateBefore />
                        </IconButton>
                    )}

                    <div> {`Pagina ${page} van ${nbPages}`} </div>
                    {nbPages > 1 && (
                        <IconButton
                            size="small"
                            onClick={() => setPage(page + 1)}
                            disabled={page === nbPages}
                        >
                            <NavigateNext />
                        </IconButton>
                    )}
                </div>
            )}
        </div>
    )
}
