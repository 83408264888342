import { CancelablePromise } from "../../../utils/cancelable-promise"
import { initialState as initialGroupPagesState } from "../group-pages/types"
import { Page } from "../group-pages/uploads-overview/page-preview/types"
import { Upload } from "../group-pages/uploads-overview/types"
import { PendingExpense } from "./../group-pages/pending-expenses-list/pending-expense/types"
import {
    ActiveStepStateType,
    initialDropzonesState,
    initialState,
    LoadingType,
    State,
    StateType,
} from "./types"

export type Action =
    | UpdateMultipleExpenseFiles
    | UpdateSingleExpenseFiles
    | OpenStepper
    | CloseStepper
    | UploadDone
    | GoBack
    | Loading
    | CancelableLoading
    | MovePage
    | MakeExpense
    | CancelPendingExpense

export enum ActionType {
    OPEN_STEPPER = "openStepper",
    CLOSE_STEPPER = "closeStepper",
    UPDATE_MULTIPLE_EXPENSE_FILES = "updateMultipleFiles",
    UPDATE_SINGLE_EXPENSE_FILES = "updateSingleFiles",
    UPLOAD_DONE = "uploadDone",
    GO_BACK = "goBack",
    START_LOADING = "startLoading",
    START_CANCELABLE_LOADING = "startCancelableLoading",
    MOVE_PAGE = "movePage",
    MAKE_EXPENSE = "makeExpense",
    CANCEL_PENDING_EXPENSE = "cancelPendingExpense",
}

export interface UpdateMultipleExpenseFiles {
    readonly type: ActionType.UPDATE_MULTIPLE_EXPENSE_FILES
    readonly payload: File[]
}

export interface UpdateSingleExpenseFiles {
    readonly type: ActionType.UPDATE_SINGLE_EXPENSE_FILES
    readonly payload: File[]
}

export interface OpenStepper {
    readonly type: ActionType.OPEN_STEPPER
}

export interface CloseStepper {
    readonly type: ActionType.CLOSE_STEPPER
}

export interface UploadDone {
    readonly type: ActionType.UPLOAD_DONE
    readonly payload: {
        uploads: Upload[]
        pendingExpenses: PendingExpense[]
    }
}

export interface GoBack {
    readonly type: ActionType.GO_BACK
}

export interface CancelableLoading {
    readonly type: ActionType.START_CANCELABLE_LOADING
    readonly payload: CancelablePromise<any>
}

export interface Loading {
    readonly type: ActionType.START_LOADING
}

export interface MovePage {
    readonly type: ActionType.MOVE_PAGE
    readonly payload: {
        page: Page
        index: number | "new"
    }
}

export interface CancelPendingExpense {
    readonly type: ActionType.CANCEL_PENDING_EXPENSE
    readonly payload: {
        pages: readonly Page[]
        pendingExpenseIndex: number
    }
}

export interface MakeExpense {
    readonly type: ActionType.MAKE_EXPENSE
    readonly payload: {
        id: number
        pages: readonly Page[]
    }
}

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.OPEN_STEPPER:
            return {
                type: StateType.Open,
                loading: {
                    type: LoadingType.Done,
                },
                activeStep: {
                    type: ActiveStepStateType.DropZones,
                    dropZonesState: initialDropzonesState(),
                },
            }
        case ActionType.CLOSE_STEPPER:
            if (
                state.type === StateType.Open &&
                state.loading.type === LoadingType.CancelableBusy
            )
                state.loading.awaitedPromises.forEach(p => p.cancel)
            return initialState()
        case ActionType.GO_BACK:
            if (
                state.type === StateType.Closed ||
                state.activeStep.type === ActiveStepStateType.DropZones
            )
                throw new Error("unreachable")
            return {
                ...state,
                activeStep: {
                    type: ActiveStepStateType.DropZones,
                    dropZonesState: state.activeStep.previousStepState,
                },
            }
        case ActionType.UPLOAD_DONE:
            if (
                state.type === StateType.Closed ||
                state.activeStep.type !== ActiveStepStateType.DropZones
            )
                throw new Error("unreachable")
            return {
                ...state,
                loading: {
                    type: LoadingType.Done,
                },
                activeStep: {
                    type: ActiveStepStateType.GroupPages,
                    groupPagesState: initialGroupPagesState(action.payload),
                    previousStepState: state.activeStep.dropZonesState,
                },
            }
        case ActionType.START_CANCELABLE_LOADING:
            if (state.type === StateType.Closed) throw new Error("unreachable")
            return {
                ...state,
                loading: {
                    type: LoadingType.CancelableBusy,
                    awaitedPromises:
                        state.loading.type === LoadingType.CancelableBusy
                            ? [...state.loading.awaitedPromises, action.payload]
                            : [action.payload],
                },
            }
        case ActionType.START_LOADING:
            if (state.type === StateType.Closed) throw new Error("unreachable")
            return {
                ...state,
                loading: {
                    type: LoadingType.Busy,
                },
            }
        case ActionType.UPDATE_MULTIPLE_EXPENSE_FILES:
            if (
                state.type === StateType.Closed ||
                state.activeStep.type !== ActiveStepStateType.DropZones
            )
                throw new Error("unreachable")
            return {
                ...state,
                activeStep: {
                    type: ActiveStepStateType.DropZones,
                    dropZonesState: {
                        ...state.activeStep.dropZonesState,
                        multipleExpenseFiles: action.payload,
                    },
                },
            }
        case ActionType.UPDATE_SINGLE_EXPENSE_FILES:
            if (
                state.type === StateType.Closed ||
                state.activeStep.type !== ActiveStepStateType.DropZones
            )
                throw new Error("unreachable")
            return {
                ...state,
                activeStep: {
                    type: ActiveStepStateType.DropZones,
                    dropZonesState: {
                        ...state.activeStep.dropZonesState,
                        singleExpenseFiles: action.payload,
                    },
                },
            }
        case ActionType.MAKE_EXPENSE: {
            if (
                state.type === StateType.Closed ||
                state.activeStep.type !== ActiveStepStateType.GroupPages
            )
                throw new Error("unreachable")
            const groupPagesState = state.activeStep.groupPagesState
            const newPendingExpense: PendingExpense = {
                pages: action.payload.pages,
            }
            const newPendingExpenses = [
                ...groupPagesState.pendingExpensesState.pendingExpenses,
                newPendingExpense,
            ]
            const newUploads = groupPagesState.uploads.map(u =>
                u.id === action.payload.id ? { ...u, pages: [] } : u
            )
            return {
                ...state,
                activeStep: {
                    ...state.activeStep,
                    groupPagesState: {
                        uploads: newUploads,
                        pendingExpensesState: {
                            ...groupPagesState.pendingExpensesState,
                            pendingExpenses: newPendingExpenses,
                        },
                    },
                },
            }
        }
        case ActionType.CANCEL_PENDING_EXPENSE: {
            if (
                state.type === StateType.Closed ||
                state.activeStep.type !== ActiveStepStateType.GroupPages
            )
                throw new Error("unreachable")
            const groupPagesState = state.activeStep.groupPagesState
            const newPendingExpenses =
                groupPagesState.pendingExpensesState.pendingExpenses.filter(
                    (_, index) => action.payload.pendingExpenseIndex !== index
                )
            const canceledPages = action.payload.pages
            const newUploads = groupPagesState.uploads.map(u => ({
                ...u,
                pages: [
                    ...u.pages,
                    ...canceledPages.filter(cp => cp.fileName === u.name),
                ],
            }))
            return {
                ...state,
                activeStep: {
                    ...state.activeStep,
                    groupPagesState: {
                        uploads: newUploads,
                        pendingExpensesState: {
                            ...groupPagesState.pendingExpensesState,
                            pendingExpenses: newPendingExpenses,
                        },
                    },
                },
            }
        }
        case ActionType.MOVE_PAGE: {
            if (
                state.type === StateType.Closed ||
                state.activeStep.type !== ActiveStepStateType.GroupPages
            )
                throw new Error("unreachable")
            const uploads = [
                ...state.activeStep.groupPagesState.uploads.map(u => ({
                    ...u,
                    pages: [
                        ...u.pages.filter(p => p.id !== action.payload.page.id),
                    ],
                })),
            ]
            if (action.payload.index === "new") {
                const newPendingExpense: PendingExpense = {
                    pages: [action.payload.page],
                }
                return {
                    ...state,
                    activeStep: {
                        ...state.activeStep,
                        groupPagesState: {
                            uploads,
                            pendingExpensesState: {
                                pendingExpenses: [
                                    newPendingExpense,
                                    ...state.activeStep.groupPagesState
                                        .pendingExpensesState.pendingExpenses,
                                ],
                            },
                        },
                    },
                }
            } else {
                const newPendingExpense: PendingExpense = {
                    pages: [
                        ...state.activeStep.groupPagesState.pendingExpensesState
                            .pendingExpenses[action.payload.index].pages,
                        action.payload.page,
                    ],
                }
                return {
                    ...state,
                    activeStep: {
                        ...state.activeStep,
                        groupPagesState: {
                            uploads,
                            pendingExpensesState: {
                                pendingExpenses:
                                    state.activeStep.groupPagesState.pendingExpensesState.pendingExpenses.map(
                                        (e, i) =>
                                            i === action.payload.index
                                                ? newPendingExpense
                                                : e
                                    ),
                            },
                        },
                    },
                }
            }
        }
        default:
            throw new Error("unreachable")
    }
}
