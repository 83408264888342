import { VatRegime } from "../generated/graphql"

export function vatRegimeToString(vatRegime: VatRegime): string {
    switch (vatRegime) {
        case VatRegime.SubjectToTax:
            return "Betalingsplichtig"
        case VatRegime.VatExempt:
            return "Vrijgesteld van BTW"
        case VatRegime.NoRightToDeductVat:
            return "Geen recht op BTW aftrek"
        case VatRegime.CoContractor:
            return "Medecontractant"
        case VatRegime.IntraCommunity:
            return "Intracommunautair"
        case VatRegime.ImportOutsideEc:
            return "Invoer buiten EG"
        case VatRegime.EcCocontractor:
            return "EG-medecontractant"
        case VatRegime.ImportOutsideEcLicensed:
            return "Invoer buiten EG (vergunning)"
    }
}
