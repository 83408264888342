import {
    Button,
    Card,
    CardContent,
    InputAdornment,
    Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { Search } from "@material-ui/icons"
import {
    DataGrid,
    GridArrowDownwardIcon,
    GridArrowUpwardIcon,
    GridCellParams,
    GridColumnHeaderParams,
    GridRowId,
    GridValueFormatterParams,
} from "@mui/x-data-grid"
import { FormEvent } from "react"
import { formatCurrencyInEuro } from "../../utils/currency-util"
import { formatDate } from "../../utils/date-util"
import { Error } from "../error/error"
import { FilterDialog } from "./filter-dialog/filter-dialog"
import {
    CellType,
    FilterItem,
    PagePayload,
    Props,
    SortDirection,
} from "./types"

export function headerRenderer(params: GridColumnHeaderParams): JSX.Element {
    return <div className="font-bold">{params.colDef.headerName}</div>
}

export function cellRenderer(
    params: GridCellParams,
    type: CellType
): JSX.Element {
    if (params.formattedValue) {
        switch (type) {
            case CellType.Normal:
                return <div>{params.formattedValue}</div>
            case CellType.Currency:
                return <div className="font-serif">{params.formattedValue}</div>
        }
    }
    return <div className="text-secondary">Ongekend</div>
}

export function currencyFormatter(
    params: GridValueFormatterParams
): string | null {
    if (typeof params.value === "number")
        return formatCurrencyInEuro(params.value)
    return null
}

export function dateFormatter(params: GridValueFormatterParams): string | null {
    if (params.value instanceof Date) return formatDate(params.value)
    return null
}

export const useStyles = makeStyles({
    root: {
        border: 0,
    },
})

Table.defaultProps = {
    searchable: false,
    enableRowSelection: false,
    onSelectionChange: () => {},
    selection: null,
}

export function Table<Row>(props: Props<Row>): JSX.Element {
    const {
        title,
        state,
        setState,
        columns,
        getInitialPages,
        pageForwards,
        pageBackwards,
        sortableColumns,
        enableRowSelection,
        onSelectionChange,
    } = props

    function setPage(newPage: number): void {
        setState({
            ...state,
            loading: true,
        })
        if (newPage < state.page) {
            pageBackwards(
                state.pageSize,
                state.startCursor,
                state.sortBy,
                state.sortDirection,
                state.submittedQuery,
                state.selectedFilterItems
            ).then(result =>
                handlePageResult(
                    newPage,
                    state.pageSize,
                    state.sortBy,
                    state.sortDirection,
                    state.submittedQuery,
                    result
                )
            )
        } else if (newPage > state.page) {
            pageForwards(
                state.pageSize,
                state.endCursor,
                state.sortBy,
                state.sortDirection,
                state.submittedQuery,
                state.selectedFilterItems
            ).then(result =>
                handlePageResult(
                    newPage,
                    state.pageSize,
                    state.sortBy,
                    state.sortDirection,
                    state.submittedQuery,
                    result
                )
            )
        }
    }

    function handlePageResult(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: SortDirection,
        submittedQuery: string,
        pagePayload: PagePayload<Row> | null,
        selectedFilterItems?: FilterItem[]
    ): void {
        if (pagePayload === null) {
            setState({
                ...state,
                error: true,
            })
        } else {
            setState({
                ...state,
                startCursor: pagePayload.startCursor,
                endCursor: pagePayload.endCursor,
                rows: pagePayload.rows,
                rowCount: pagePayload.rowCount,
                page,
                sortBy,
                pageSize,
                sortDirection,
                submittedQuery,
                loading: false,
                selectedFilterItems:
                    selectedFilterItems ?? state.selectedFilterItems,
                filterDialogOpen: false,
            })
        }
    }

    function setPageSize(pageSize: number): void {
        setState({
            ...state,
            loading: true,
        })
        getInitialPages(
            pageSize,
            state.sortBy,
            state.sortDirection,
            state.submittedQuery
        ).then(pagePayload => {
            handlePageResult(
                0,
                pageSize,
                state.sortBy,
                state.sortDirection,
                state.submittedQuery,
                pagePayload
            )
        })
    }

    function onSearchSubmit(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault()
        const query = state.searchText
        setState({
            ...state,
            loading: true,
        })
        getInitialPages(
            state.pageSize,
            state.sortBy,
            state.sortDirection,
            query,
            state.selectedFilterItems
        ).then(pagePayload => {
            handlePageResult(
                0,
                state.pageSize,
                state.sortBy,
                state.sortDirection,
                query,
                pagePayload
            )
        })
    }

    function handleSortClick(sortBy: string): void {
        if (sortBy === state.sortBy) {
            const newSortDirection =
                state.sortDirection === SortDirection.Asc
                    ? SortDirection.Desc
                    : SortDirection.Asc
            getInitialPages(
                state.pageSize,
                state.sortBy,
                newSortDirection,
                state.submittedQuery
            ).then(pagePayload => {
                handlePageResult(
                    0,
                    state.pageSize,
                    state.sortBy,
                    newSortDirection,
                    state.submittedQuery,
                    pagePayload
                )
            })
        } else {
            getInitialPages(
                state.pageSize,
                sortBy,
                SortDirection.Desc,
                state.submittedQuery
            ).then(pagePayload => {
                handlePageResult(
                    0,
                    state.pageSize,
                    sortBy,
                    SortDirection.Desc,
                    state.submittedQuery,
                    pagePayload
                )
            })
        }
    }

    function onFilterDialogSave(selectedItems: FilterItem[]): void {
        setState({
            ...state,
            loading: true,
            filterDialogOpen: false,
        })
        getInitialPages(
            state.pageSize,
            state.sortBy,
            state.sortDirection,
            state.submittedQuery,
            selectedItems
        ).then(pagePayload => {
            handlePageResult(
                0,
                state.pageSize,
                state.sortBy,
                state.sortDirection,
                state.submittedQuery,
                pagePayload,
                selectedItems
            )
        })
    }

    function onFilterDialogCancel(): void {
        setState({
            ...state,
            filterDialogOpen: false,
        })
    }

    function onSelectionModelChange(selectionModel: GridRowId[]): void {
        if (selectionModel.length === 0) {
            onSelectionChange(null)
        } else {
            onSelectionChange(selectionModel[0].toString())
        }
    }

    const classes = useStyles()

    if (state.error)
        return (
            <Error
                message={"Er ging iets mis bij het ophalen van de uitgaven."}
            />
        )

    return (
        <Card>
            <div className="flex flex-row justify-between space-x-4 m-4">
                <Typography variant="h5">{title || ""}</Typography>
                <div className="flex flex-row items-center space-x-4">
                    <form onSubmit={e => onSearchSubmit(e)}>
                        <TextField
                            variant="outlined"
                            label="Zoek"
                            value={state.searchText}
                            onChange={t =>
                                setState({
                                    ...state,
                                    searchText: t.target.value,
                                })
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                    <div className="flex flex-row space-x-4 items-center">
                        <div>Sorteer:</div>
                        {sortableColumns.map(s => (
                            <Button
                                startIcon={
                                    s.column === state.sortBy ? (
                                        state.sortDirection ===
                                        SortDirection.Asc ? (
                                            <GridArrowUpwardIcon />
                                        ) : (
                                            <GridArrowDownwardIcon />
                                        )
                                    ) : (
                                        <GridArrowDownwardIcon />
                                    )
                                }
                                onClick={() => handleSortClick(s.column)}
                                color={
                                    state.sortBy === s.column
                                        ? "primary"
                                        : undefined
                                }
                            >
                                {s.label}
                            </Button>
                        ))}
                    </div>
                    <div></div>
                    {state.selectedFilterItems && props.availableFilterItems && (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    setState({
                                        ...state,
                                        filterDialogOpen: true,
                                    })
                                }
                            >
                                SELECTEER REKENINGEN
                            </Button>
                            <FilterDialog
                                open={state.filterDialogOpen}
                                selectedItems={state.selectedFilterItems}
                                availableItems={props.availableFilterItems}
                                onSave={selectedFilterItems =>
                                    onFilterDialogSave(selectedFilterItems)
                                }
                                onCancel={onFilterDialogCancel}
                            />
                        </div>
                    )}
                </div>
            </div>
            <CardContent>
                <DataGrid
                    rows={state.rows}
                    columns={columns}
                    autoHeight
                    classes={classes}
                    paginationMode="server"
                    page={state.page}
                    onPageChange={setPage}
                    pageSize={state.pageSize}
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    rowCount={state.rowCount}
                    loading={state.loading}
                    sortingMode="server"
                    disableSelectionOnClick={!enableRowSelection}
                    onSelectionModelChange={onSelectionModelChange}
                />
            </CardContent>
        </Card>
    )
}
