import { GridColumns } from "@mui/x-data-grid"
import { SortOrder } from "../../generated/graphql"

export enum SortDirection {
    Asc = "asc",
    Desc = "desc",
}

export function toSortOrder(sortDirection: SortDirection): SortOrder {
    switch (sortDirection) {
        case SortDirection.Asc:
            return SortOrder.Asc
        case SortDirection.Desc:
            return SortOrder.Desc
    }
}

export const INITIAL_SORT_DIRECTION = SortDirection.Desc

export interface PagePayload<Row> {
    readonly rows: Row[]
    readonly startCursor: string
    readonly endCursor: string
    readonly rowCount: number
}

export type PageBackwards<PagePayload> = (
    last: number,
    before: string,
    sortBy: string,
    sortDirection: SortDirection,
    query: string,
    selectedFilterItems?: FilterItem[]
) => Promise<PagePayload | null>

export type PageForwards<PagePayload> = (
    first: number,
    after: string,
    sortBy: string,
    sortDirection: SortDirection,
    query: string,
    selectedFilterItems?: FilterItem[]
) => Promise<PagePayload | null>

export type GetInitialPages<PagePayload> = (
    pageSize: number,
    sortBy: string,
    sortDirection: SortDirection,
    query: string,
    selectedFilterItems?: FilterItem[]
) => Promise<PagePayload | null>

export interface State<Row> {
    readonly rows: Row[]
    readonly page: number
    readonly pageSize: number
    readonly rowCount: number
    readonly startCursor: string
    readonly endCursor: string
    readonly loading: boolean
    readonly sortBy: string
    readonly sortDirection: SortDirection
    readonly error: boolean
    readonly searchText: string
    readonly submittedQuery: string
    readonly selectedFilterItems?: FilterItem[]
    readonly filterDialogOpen: boolean
}

export interface Props<Row> {
    readonly title?: string
    readonly state: State<Row>
    readonly setState: (state: State<Row>) => void
    readonly columns: GridColumns
    readonly sortableColumns: SortableColumn[]
    readonly pageBackwards: PageBackwards<PagePayload<Row>>
    readonly pageForwards: PageForwards<PagePayload<Row>>
    readonly getInitialPages: GetInitialPages<PagePayload<Row>>
    readonly enableRowSelection: boolean
    readonly selection: string | null
    readonly onSelectionChange: (selectedId: string | null) => void
    readonly availableFilterItems?: FilterItem[]
}

export interface SortableColumn {
    readonly column: string
    readonly label: string
}

export enum CellType {
    Currency,
    Normal,
    Paid,
}

export interface FilterItem {
    id: string
    label: string
}

export function initialState<Row>(
    rows: Row[],
    pageSize: number,
    rowCount: number,
    sortBy: string,
    startCursor: string,
    endCursor: string,
    selectedFilterItems?: FilterItem[]
): State<Row> {
    return {
        rows,
        page: 0,
        pageSize,
        rowCount,
        startCursor,
        endCursor,
        loading: false,
        sortBy,
        sortDirection: INITIAL_SORT_DIRECTION,
        error: false,
        searchText: "",
        submittedQuery: "",
        selectedFilterItems,
        filterDialogOpen: false,
    }
}
