import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@material-ui/core"
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded"
import React from "react"
import { useDeleteCategoryMutation } from "../../../../generated/graphql"
import { Props } from "./types"

export function DeleteCategory(props: Props): JSX.Element {
    const [deleteCategory] = useDeleteCategoryMutation({
        refetchQueries: ["client"],
    })

    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    function close(): void {
        if (loading) return
        setOpen(false)
    }

    function save(): void {
        setLoading(true)
        deleteCategory({
            variables: {
                categoryId: props.category.id,
            },
        }).then(result => {
            setLoading(false)
            if (!result.data) alert("Er ging iets mis.")
            close()
        })
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <DeleteForeverRoundedIcon />
            </IconButton>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Verwijder categorie</DialogTitle>
                <DialogContent>
                    <div className="m-4 w-64">
                        Ben je zeker dat je de categorie "{props.category.name}"
                        permanent wil verwijderen? Deze actie kan niet ongedaan
                        gemaakt worden.
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} disabled={loading}>
                        ANNULEER
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={save}
                    >
                        VERWIJDER
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
