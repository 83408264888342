import { Props } from "./types"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import { TransactionOverview } from "../transaction-overview/transaction-overview"
import { AddLinkedExpensesTable } from "../add-linked-expenses-table/add-linked-expenses-table"
import { State as AddLinkedExpensesTableState } from "../add-linked-expenses-table/types"
import { ExpensePDF } from "../../expense/expense-pdf/expense-pdf"

export function EditLinkedExpensesDialog(props: Props): JSX.Element {
    const {
        open,
        onCancel,
        onSave,
        transactionOverview,
        state,
        setState,
        pageForwards,
        pageBackwards,
        getInitialPages,
        previouslyLinkedExpenseIds,
    } = props

    function setSelectLinkedExpensesTableState(
        addLinkedExpensesTableState: AddLinkedExpensesTableState
    ): void {
        setState({
            ...state,
            addLinkedexpensesTableState: addLinkedExpensesTableState,
        })
    }

    function setSelectedExpenseIds(ids: string[]): void {
        setState({
            ...state,
            selectedExpenseIds: ids,
        })
    }

    function setShownExpenseUrl(url: string | null): void {
        setState({
            ...state,
            shownExpenseUrl: url,
        })
    }

    return (
        <Dialog className="m-4" fullScreen onClose={onCancel} open={open}>
            <DialogTitle>Gekoppelde uitgaven</DialogTitle>
            <DialogContent>
                <div className="flex flex-row w-full h-full">
                    <div className="w-7/12 h-full overflow-scroll">
                        <div className="flex flex-col space-y-4">
                            <TransactionOverview
                                transactionOverview={transactionOverview}
                            />
                            <AddLinkedExpensesTable
                                setState={setSelectLinkedExpensesTableState}
                                state={state.addLinkedexpensesTableState}
                                previouslyLinkedExpenseIds={
                                    previouslyLinkedExpenseIds
                                }
                                pageForwards={pageForwards}
                                getInitialPages={getInitialPages}
                                pageBackwards={pageBackwards}
                                setSelectedExpensePDFUrl={setShownExpenseUrl}
                                selectedExpenseIds={state.selectedExpenseIds}
                                setSelectedExpenseIds={setSelectedExpenseIds}
                            />
                        </div>
                    </div>
                    <div className="w-5/12 h-full">
                        {state.shownExpenseUrl === null ? null : (
                            <ExpensePDF url={state.shownExpenseUrl} />
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>ANNULEREN</Button>
                <Button
                    onClick={() => onSave(state.selectedExpenseIds)}
                    variant="contained"
                    color="primary"
                >
                    OPSLAAN
                </Button>
            </DialogActions>
        </Dialog>
    )
}
