import IconButton from "@material-ui/core/IconButton"
import { GridColDef } from "@mui/x-data-grid"
import {
    cellRenderer,
    currencyFormatter,
    dateFormatter,
    headerRenderer,
    Table,
} from "../../../components/table/table"
import {
    CellType,
    SortableColumn,
    State as TableState,
} from "../../../components/table/types"
import { routeToExpense } from "../../../constants"
import { BookingPeriod } from "../../../generated/graphql"
import { history } from "../../../providers/browser-history"
import { ExpenseRow, Props } from "./types"

function bookingPeriodToColumnHeader(bookingPeriod: BookingPeriod): string {
    switch (bookingPeriod) {
        case BookingPeriod.Monthly:
            return "Maand"
        case BookingPeriod.Quarterly:
            return "Kwartaal"
    }
}

const sortableColumns: SortableColumn[] = [
    {
        column: "id",
        label: "Laatst toegevoegd",
    },
    {
        column: "supplier",
        label: "Leverancier",
    },
    {
        column: "date",
        label: "Datum",
    },
    {
        column: "grossAmount",
        label: "Bruto",
    },
]

export function ExpenseTable(props: Props): JSX.Element {
    const {
        state,
        setState,
        pageBackwards,
        pageForwards,
        getInitialPages,
        bookingPeriod,
    } = props

    function getColumns(bookingPeriod: BookingPeriod): GridColDef[] {
        return [
            {
                field: "id",
                sortable: false,
                disableColumnMenu: true,
                renderCell: params => (
                    <div className="m-auto">
                        <IconButton
                            onClick={() =>
                                history.push(routeToExpense(params.id))
                            }
                        >
                            <span className="material-icons-outlined">
                                summarize
                            </span>
                        </IconButton>
                    </div>
                ),
                renderHeader: () => <div />,
            },
            {
                field: "isPaid",
                sortable: false,
                disableColumnMenu: true,
                headerName: "Betaald",
                renderCell: params =>
                    isPaid(params.id.toString()) ? (
                        <span
                            className="material-icons-outlined"
                            style={{ color: "green" }}
                        >
                            done
                        </span>
                    ) : (
                        <span
                            className="material-icons-outlined"
                            style={{ color: "red" }}
                        >
                            clear
                        </span>
                    ),
                renderHeader: headerRenderer,
            },
            {
                field: "docString",
                sortable: false,
                disableColumnMenu: true,
                headerName: "Factuurnummer",
                flex: 1.5,
                renderCell: params => cellRenderer(params, CellType.Normal),
                renderHeader: headerRenderer,
            },
            {
                field: "bookingPeriod",
                sortable: false,
                disableColumnMenu: true,
                headerName: bookingPeriodToColumnHeader(bookingPeriod),
                flex: 1,
                renderCell: params => cellRenderer(params, CellType.Normal),
                renderHeader: headerRenderer,
            },
            {
                field: "type",
                sortable: false,
                disableColumnMenu: true,
                headerName: "AF/CN",
                flex: 1,
                renderCell: params => cellRenderer(params, CellType.Normal),
                renderHeader: headerRenderer,
            },
            {
                field: "supplier",
                sortable: false,
                disableColumnMenu: true,
                headerName: "Leverancier",
                flex: 1.5,
                renderCell: params => cellRenderer(params, CellType.Normal),
                renderHeader: headerRenderer,
            },
            {
                field: "date",
                headerName: "Datum document",
                sortable: false,
                disableColumnMenu: true,
                flex: 1.5,
                valueFormatter: dateFormatter,
                renderCell: params => cellRenderer(params, CellType.Normal),
                renderHeader: headerRenderer,
            },
            {
                field: "grossAmount",
                sortable: false,
                disableColumnMenu: true,
                headerName: "Bruto",
                flex: 1,
                valueFormatter: currencyFormatter,
                renderCell: params => cellRenderer(params, CellType.Currency),
                renderHeader: headerRenderer,
            },
            {
                field: "netAmount",
                sortable: false,
                disableColumnMenu: true,
                headerName: "Netto",
                flex: 1,
                valueFormatter: currencyFormatter,
                renderCell: params => cellRenderer(params, CellType.Currency),
                renderHeader: headerRenderer,
            },
            {
                field: "reference",
                sortable: false,
                disableColumnMenu: true,
                headerName: "Referentie",
                flex: 1.5,
                renderCell: params => cellRenderer(params, CellType.Normal),
                renderHeader: headerRenderer,
            },
        ]
    }

    function isPaid(id: string): boolean {
        return state.tableState.rows.some(r => r.isPaid && r.id === id)
    }

    function setTableState(tableState: TableState<ExpenseRow>): void {
        setState({
            tableState,
        })
    }

    return (
        <Table
            title="Uitgaven"
            state={state.tableState}
            setState={setTableState}
            columns={getColumns(bookingPeriod)}
            sortableColumns={sortableColumns}
            pageBackwards={pageBackwards}
            pageForwards={pageForwards}
            getInitialPages={getInitialPages}
        />
    )
}
